<template>
  <div class="is-flex is-flex-direction-column">
    <div>
      <h3 class="title">
        {{ $tf("contractForm.base.title|Vállalás lebontása") }}
      </h3>
    </div>
    <form @submit.prevent>
      <div class="columns">
        <div class="column">
          <div class="money-subtext">
            Megadott vállalás:
            <span>{{ workdayify(projectCommitment) }}</span>
          </div>
        </div>
        <div class="column">
          <div class="money-subtext">
            Lebontott vállalás:
            <span>{{ workdayify(commitmentDetailsSum) }}</span>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field
            :label="$tf('commitmentModal.base.competence|Kompetencia')"
            label-position="on-border"
          >
            <vuelidated-input
              type="autocomplete"
              :validation-rule="v$.commitmentEdit.competence"
              v-model="commitmentEdit.competence"
              :data="competencies"
              append-to-body
              open-on-focus
              clearable
              field="name"
              icon="search"
              rounded
              class="has-blue-icon"
              expanded
            >
              <template #empty>{{
                $tf("projectForm.manager.empty|Nincs találat")
              }}</template>
            </vuelidated-input>
          </b-field>
        </div>
        <div class="column">
          <b-field
            :label="$tf('commitmentModal.base.seniority|Senioritás')"
            label-position="on-border"
          >
            <vuelidated-input
              type="autocomplete"
              :validation-rule="{}"
              v-model="commitmentEdit.seniority"
              :data="seniorities"
              append-to-body
              open-on-focus
              clearable
              field="name"
              icon="search"
              rounded
              class="has-blue-icon"
              expanded
            >
              <template #empty>{{
                $tf("projectForm.manager.empty|Nincs találat")
              }}</template>
            </vuelidated-input>
          </b-field>
        </div>
        <div class="column">
          <b-field
            :label="$tf('commitmentModal.base.workDays|Vállalt napok')"
            label-position="on-border"
          >
            <vuelidated-input
              :validation-rule="v$.commitmentEdit.workDays"
              v-model="commitmentEdit.workDays"
              type="number"
              :expanded="true"
            ></vuelidated-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-button
            native-type="submit"
            type="is-info"
            :expanded="true"
            @click="$emit('add-commitment', commitmentEdit)"
            >Mentés</b-button
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { minValue, required } from "@vuelidate/validators";
import { deepCopy, workdayify } from "@/utils/util";
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";

export default {
  name: "CommitmentModal",
  components: { VuelidatedInput },
  methods: { workdayify },
  emits: ["add-commitment"],
  setup: () => ({ v$: useVuelidate() }),
  props: {
    editedCommitment: {
      type: Object,
      default: () => null,
    },
    competencies: {
      type: Array,
      default: () => [],
    },
    seniorities: {
      type: Array,
      default: () => [],
    },
    projectCommitment: {
      type: Number,
      default: 0,
    },
    commitmentDetailsSum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      commitmentEdit: {},
    };
  },
  validations: {
    commitmentEdit: {
      competence: {
        required,
      },
      workDays: {
        required,
        minValue: minValue(1),
      },
    },
  },
  mounted() {
    if (this.editedCommitment) {
      this.commitmentEdit = deepCopy(this.editedCommitment);
    }
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.money-subtext {
  color: $turquoise;
  font-weight: 400;
  span {
    color: $custom-dark-blue;
    font-weight: 500;
  }
}
</style>
