<template>
  <section class="section">
    <time-sheet-summary v-if="loggingRequired && !isLoading">
    </time-sheet-summary>

    <div class="is-flex has-right-margin" v-if="!isLoading">
      <div class="container p-4 mr-0" style="flex: 1">
        <h3 class="heading mb-0">
          <loading-component>
            {{ $tf("dashboardSummary.type|Típus") }}
          </loading-component>
        </h3>
        <loading-component>
          <b-tabs
            v-model="isShowingAbsenceOrPlace"
            type="is-toggle"
            class="toggle-only"
            vertical
          >
            <b-tab-item
              value="Hiányzások"
              :label="$tf('dashboardSummary.absences|Hiányzások')"
            />
            <b-tab-item
              value="Beosztások"
              :label="$tf('dashboard.workschedules|Beosztások')"
            />
          </b-tabs>
        </loading-component>
        <div v-if="teamTags.length">
          <h3 class="heading mb-2 mt-4">
            <loading-component>{{
              $tf("dashboardSummary.filterTeams|Szűrés csapatokra")
            }}</loading-component>
          </h3>
          <loading-component class="is-flex is-flex-wrap-wrap has-gap-2">
            <template #loader>
              <div-with-loading
                v-for="n in 20"
                :key="n"
                loading-height="32px"
                loading-width="100%"
              ></div-with-loading>
            </template>
            <div
              v-for="(team, index) in teamTags"
              :key="index"
              class="absence-tag"
              :style="getTeamColor(index, team.selected)"
              @click="clickOnTeamTag(index)"
            >
              <div class="is-flex is-align-items-center has-gap-1">
                <b-icon size="is-small" v-if="team.selected" icon="check" />
                <img
                  :src="getAvatarForTeam(team)"
                  alt=""
                  style="width: 24px; height: 24px; border-radius: 50px"
                />
                <span>{{ team.name }}</span>
              </div>
            </div>
          </loading-component>
        </div>

        <div>
          <template v-if="isShowingAbsenceOrPlace === 'Beosztások'">
            <h3 class="heading mb-2 mt-4">
              <loading-component>{{
                $tf("dashboardSummary.filterWorkplaces|Szűrés irodákra")
              }}</loading-component>
            </h3>
            <loading-component>
              <template #loader></template>
              <div class="is-flex is-flex-wrap-wrap has-gap-2">
                <b-tag
                  v-for="(place, index) in this.workplaceTags"
                  :key="index"
                  class="absence-tag"
                  :style="style(place.color, place.selected)"
                  @click="clickOnTag(index)"
                  :icon="place.selected ? 'check' : null"
                >
                  {{ place.identifier }}
                </b-tag>
              </div>
            </loading-component>
          </template>

          <!--          <template v-else>-->
          <!--            <h3 class="heading mb-2 mt-4">Szűrés hiányzástípusra</h3>-->
          <!--            <div class="is-flex is-flex-wrap-wrap has-gap-2">-->
          <!--              <b-tag-->
          <!--                v-for="(type, index) in absenceTypeTags"-->
          <!--                :key="index"-->
          <!--                class="absence-tag"-->
          <!--                :style="style(type.color, type.selected)"-->
          <!--                @click="clickOnAbsenceTypeTag(index)"-->
          <!--                :icon="type.selected ? 'check' : null"-->
          <!--              >-->
          <!--                {{ type.name }}-->
          <!--              </b-tag>-->
          <!--            </div>-->
          <!--          </template>-->
        </div>

        <loading-component>
          <b-button
            class="mt-5"
            type="is-light"
            :label="$tf('dashboardSummary.resetFilters|Szűrők törlése')"
            @click="resetFilters"
          />
        </loading-component>
      </div>
      <div style="flex: 10">
        <WeeklyCalendar
          v-if="
            absences && fromDate && isShowingAbsenceOrPlace === 'Hiányzások'
          "
          :absences="absences"
          :absence-types-to-show="
            absenceTypeTags.some((tag) => tag.selected)
              ? absenceTypeTags.filter((tag) => tag.selected)
              : absenceTypeTags
          "
          :employees-to-show="getEmployeesOfSelectedTeams()"
          :teams-to-show="
            getSelectedTeams().length === 0 ? teams : getSelectedTeams()
          "
        />
        <WeeklyCalendar
          v-else-if="
            workschedules &&
            fromDate &&
            isShowingAbsenceOrPlace === 'Beosztások'
          "
          :workschedules="workschedules"
          :workplaces-to-show="
            workplaceTags.some((tag) => tag.selected)
              ? workplaceTags.filter((tag) => tag.selected)
              : workplaceTags
          "
          :employees-to-show="getEmployeesOfSelectedTeams()"
          :teams-to-show="
            getSelectedTeams().length === 0 ? teams : getSelectedTeams()
          "
        />
      </div>
    </div>
  </section>
</template>

<script>
import WeeklyCalendar from "../../components/dashboard/WeeklyCalendar";
import TimeSheetSummary from "@/components/timesheet/TimeSheetSummary";
import { mapGetters } from "vuex";
import { deepCopy, getContrastedColor, hslToHex } from "@/utils/util";
import { TEAM_LEAD_ROLE } from "@/utils/const";
import LoadingComponent from "@/components/loading/LoadingComponent.vue";
import DivWithLoading from "@/components/loading/DivWithLoading.vue";

export default {
  name: "PortalDashboard",
  async mounted() {
    await this.fetchData();
    this.workplaceTags = deepCopy(this.workplaces) ?? [];
    this.workplaceTags.forEach((tag) => (tag.selected = false));
    console.log(this.teams);
    if (this.teams) {
      this.teamTags = deepCopy(this.teams);
      this.teamTags.forEach((tag) => (tag.selected = false));
    }
    this.absenceTypeTags = deepCopy(this.absenceTypes);
    this.absenceTypeTags.forEach((tag) => (tag.selected = false));
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: true,
      isShowingAbsenceOrPlace: "Hiányzások",
      workplaceTags: [],
      teamTags: [],
      absenceTypeTags: [],
      selectedTeamsToShow: [],
      selectedTeamsToShowFiltered: [],
    };
  },
  components: {
    DivWithLoading,
    WeeklyCalendar,
    TimeSheetSummary,
    LoadingComponent,
  },
  watch: {
    actWeek() {
      if (this.isShowingAbsenceOrPlace === "Hiányzások") {
        this.$store.dispatch("dashboard/fetch");
      } else {
        this.$store.dispatch("dashboard/fetchWorkSchedules");
      }
    },

    isShowingAbsenceOrPlace() {
      if (this.isShowingAbsenceOrPlace === "Hiányzások") {
        this.$store.dispatch("dashboard/fetch");
      } else {
        this.$store.dispatch("dashboard/fetchWorkSchedules");
      }
    },
    actYear() {
      this.$store.dispatch("specialday/getSpecialDaysAll");
    },
  },
  computed: {
    ...mapGetters({
      absences: "dashboard/absences",
      workschedules: "dashboard/workschedules",
      workplaces: "work_schedule/workScheduleSites",
      actWeek: "dashboard/actWeek",
      actYear: "dashboard/actYear",
      fromDate: "dashboard/fromDate",
      toDate: "dashboard/toDate",
      specialDays: "specialday/specialDaysAll",
      teams: "census_team/teams",
      absenceTypes: "absence_request/types",
      loggingRequired: "session/loggingRequired",
      employees: "employee/employees",
    }),
  },
  methods: {
    async fetchData() {
      const promises = [];
      promises.push(
        this.$store.dispatch("dashboard/fetch"),
        this.$store.dispatch("specialday/getSpecialDaysAll"),
        this.$store.dispatch("work_schedule/getWorkScheduleAdminSites"),
        this.$store.dispatch("census_team/fetchTeams"),
        this.$store.dispatch("absence_request/getAdminTypes"),
        this.$store.dispatch("employee/fetchColleagues")
      );
      await Promise.all(promises);
    },
    async prevWeek() {
      await this.$store.dispatch("dashboard/prevWeek");
    },
    async nextWeek() {
      await this.$store.dispatch("dashboard/nextWeek");
    },
    async resetActWeek() {
      await this.$store.dispatch("dashboard/resetActWeek");
    },
    style(color, selected) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
      result = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
      let selectedHex = selected ? "FF" : "BB";
      selected = selected ? "1" : "0.3";
      return (
        "color: " +
        getContrastedColor(color) +
        selectedHex +
        ";" +
        "background-color: rgba(" +
        result.r +
        ", " +
        result.g +
        ", " +
        result.b +
        ", " +
        selected +
        ");"
      );
    },
    getTeamColor(index, selected) {
      const hue = index * 137.508; // use golden angle approximation
      return this.style(hslToHex(hue, 50, 75), selected);
    },
    clickOnTag(tagId) {
      let tag = this.workplaceTags[tagId];
      tag.selected = !tag.selected;
      this.workplaceTags.tagId = tag;
    },
    clickOnTeamTag(tagId) {
      let tag = this.teamTags[tagId];
      tag.selected = !tag.selected;
      this.teamTags.tagId = tag;
    },
    clickOnAbsenceTypeTag(tagId) {
      let tag = this.absenceTypeTags[tagId];
      tag.selected = !tag.selected;
      this.absenceTypeTags.tagId = tag;
    },
    getSelectedTeams() {
      return this.teamTags.filter((tag) => tag.selected);
    },
    getEmployeesOfSelectedTeams() {
      let colleagues = [];
      if (this.getSelectedTeams().length === 0) return null;
      this.getSelectedTeams().forEach((team) =>
        team.employees
          .concat(team.leads)
          .forEach((employee) => colleagues.push(employee))
      );
      return colleagues;
    },
    resetFilters() {
      this.teamTags.forEach((tag, tagIndex) => {
        tag.selected = false;
        this.teamTags.tagIndex = tag;
      });
      this.workplaceTags.forEach((tag, tagIndex) => {
        tag.selected = false;
        this.workplaceTags.tagIndex = tag;
      });
      this.absenceTypeTags.forEach((tag, tagIndex) => {
        tag.selected = false;
        this.absenceTypeTags.tagIndex = tag;
      });
      this.isShowingAbsenceOrPlace = "Hiányzások";
    },
    getAvatarForTeam(team) {
      if (!team.leads.length) {
        return "";
      }

      let leadId = null;

      let lead = team.leads.find(
        (lead) => lead.role === TEAM_LEAD_ROLE.ENUM.RESOURCE
      );
      if (lead) {
        leadId = lead.employeeId;
      } else {
        lead = team.leads.find(
          (lead) => lead.role === TEAM_LEAD_ROLE.ENUM.FINANCIAL
        );

        if (lead) {
          leadId = lead.employeeId;
        } else {
          return "";
        }
      }

      let employee = this.employees.filter((emp) => {
        return emp.id === leadId;
      });
      return employee[0]
        ? employee[0].avatar
        : "https://cdn2.iconfinder.com/data/icons/social-flat-buttons-3/512/anonymous-512.png";
    },
  },
};
</script>

<style lang="scss">
.absence-dashboard-filter-button {
  margin-top: 10px;
  margin-right: 10px;
}
.absence-dashboard-reset-selection-button {
  margin-top: 10px;
}
.absence-tag {
  user-select: none;
  cursor: pointer;
  font-size: 0.75rem;
  border-radius: 4px;
  padding: 4px 6px;
}
</style>
