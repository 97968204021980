<template>
  <div
    @click="onClick"
    @mouseover="toggleHeart"
    @mouseout="restoreHeart"
    class="heart-container"
    :class="{ 'cursor-pointer': clickable }"
  >
    <b-icon v-if="f" icon="heart" />
    <b-icon v-else icon="heart" pack="far" />
  </div>
</template>

<script>
export default {
  name: "HeartIcon",
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      f: this.$props.filled,
    };
  },
  methods: {
    toggleHeart() {
      if (this.clickable) this.f = !this.filled;
    },
    restoreHeart() {
      if (this.clickable) this.f = this.filled;
    },
    onClick() {
      if (this.clickable) this.$emit("click");
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/colors.scss";

.heart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  color: $red;
  span.icon {
    svg {
      width: 2rem !important;
    }
  }
}
</style>
