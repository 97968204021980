<template>
  <div>
    <h3 class="title">
      {{ $tf("taskForm.base.title|Új jegy létrehozása") }}
    </h3>
    <form @submit.prevent>
      <div class="columns mt-5">
        <div class="column">
          <vuelidated-input
            v-model="taskEdit.name"
            :placeholder="$tf('taskForm.name.placeholder|Feladat neve')"
            :label="$tf('taskForm.base.name|Név')"
            :validation-rule="v$.taskEdit.name"
            label-position="on-border"
            expanded
          />
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <vuelidated-input
            v-model="taskEdit.key"
            :placeholder="$tf('taskForm.identifier.placeholder|ABC-123')"
            :label="$tf('taskForm.base.identifier|Azonosító')"
            :validation-rule="v$.taskEdit.key"
            label-position="on-border"
            expanded
          />
        </div>
        <div class="column is-half">
          <b-field
            :label="$tf('taskForm.base.tig|Mérföldkő')"
            label-position="on-border"
          >
            <b-select
              v-model="taskEdit.tigId"
              :placeholder="$tf('taskForm.base.tig.placeholder|TIG-1')"
              icon="swatchbook"
              expanded
            >
              <option v-for="tig in tigs" v-bind:key="tig.id" :value="tig.id">
                {{ tig.name }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-half">
          <b-field
            :label="$tf('taskForm.base.type|Típus')"
            label-position="on-border"
          >
            <vuelidated-input
              v-model="taskEdit.type"
              :validation-rule="v$.taskEdit.type"
              type="select"
              expanded
            >
              <template #select>
                <option
                  :value="status"
                  v-for="status in Object.keys(TASK_TYPE)"
                  :key="status"
                >
                  {{ TASK_TYPE[status] }}
                </option>
              </template>
            </vuelidated-input>
          </b-field>
        </div>
        <div class="column is-half">
          <vuelidated-input
            v-model="taskEdit.estimateSeconds"
            :placeholder="$tf('taskForm.estimateSeconds.placeholder|2d 4h 30m')"
            :label="$tf('taskForm.base.estimateSeconds|Becsült idő')"
            :validation-rule="v$.taskEdit.estimateSeconds"
            label-position="on-border"
            expanded
          />
        </div>
        <div class="column">
          <b-field
            :label="$tf('taskForm.base.assignee|Hozzárendelt személy')"
            label-position="on-border"
          >
            <b-autocomplete
              v-model="taskEdit.assignee"
              icon="search"
              :data="filteredEmployees"
              field="name"
              id-field="id"
              clearable
              rounded
              class="has-blue-icon"
              :placeholder="
                $tf('taskForm.assignee.placeholder|pl. Elektrom Ágnes')
              "
            >
              <template v-slot:empty>{{
                $tf("taskForm.assignee.empty|Nincs találat")
              }}</template>
            </b-autocomplete>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field
            :label="$tf('taskForm.base.description|Leírás')"
            label-position="on-border"
          >
            <b-input v-model="taskEdit.description" type="textarea"></b-input>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-button
            native-type="submit"
            type="is-info"
            :expanded="true"
            @click="submitTask"
            >Mentés</b-button
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import { required } from "@vuelidate/validators";
import { TASK_TYPE } from "@/utils/const";
import { deepCopy } from "@/utils/util";
import { useVuelidate } from "@vuelidate/core";

const isJiraTime = (val) => /^ *([0-9]+[DdHhMm] *)*$/.test(val);

export default {
  name: "TaskForm",
  computed: {
    TASK_TYPE() {
      return TASK_TYPE;
    },
    filteredEmployees() {
      let employees = this.employees || [];
      return employees.filter((element) => {
        if (!this.taskEdit.assignee || !element.name || !element.username)
          return false;
        return (
          element.name
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.taskEdit.assignee
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.username
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.taskEdit.assignee
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
  },
  components: { VuelidatedInput },
  setup: () => ({ v$: useVuelidate() }),
  emits: ["task-submitted"],
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    projectType: {
      type: String,
      required: true,
    },
    tigs: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
    editedTask: {
      type: Object,
      required: false,
    },
    isBillable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      taskEdit: {
        estimateSeconds: "",
      },
    };
  },
  validations: {
    taskEdit: {
      name: {
        required,
      },
      key: {
        required,
      },
      tigId: {
        required,
      },
      type: {
        required,
      },
      estimateSeconds: {
        isJiraTime,
      },
    },
  },
  methods: {
    async submitTask() {
      let request = deepCopy(this.taskEdit);
      if (this.isBillable) {
        if (this.editedTask) {
          await this.$store.dispatch(
            "support_projects/updateBillableTask",
            request
          );
        } else {
          request.projectId = this.projectId;
          request.projectType = this.projectType;
          request.tigType = "BILLABLE";
          await this.$store.dispatch(
            "support_projects/createBillableTask",
            request
          );
        }
      } else {
        if (this.editedTask) {
          await this.$store.dispatch("enterprise_core/editIssue", request);
        } else {
          request.projectId = this.projectId;
          request.projectType = this.projectType;
          await this.$store.dispatch("enterprise_core/createIssue", request);
        }
      }

      this.$emit("task-submitted");
    },
    secondsToJiraTime(seconds) {
      let days = Math.floor(seconds / 28800);
      let hours = Math.floor((seconds - days * 28800) / 3600);
      let minutes = Math.floor((seconds - days * 28800 - hours * 3600) / 60);
      return (
        (days > 0 ? days + "d " : "") +
        (hours > 0 ? hours + "h " : "") +
        (minutes > 0 ? minutes + "m" : "")
      );
    },
  },
  created() {
    if (this.editedTask) {
      this.taskEdit = deepCopy(this.editedTask);
      this.taskEdit.estimateSeconds = this.secondsToJiraTime(
        this.editedTask.estimateSeconds
      );
    }
    if (this.tigs.length === 1) {
      this.taskEdit.tigId = this.tigs[0].id;
    }
  },
};
</script>

<style scoped lang="scss"></style>
