<template>
  <main>
    <div class="modal-card-head">
      <h1 class="title has-text-centered">Munkaidő rögzítése</h1>
    </div>
    <div class="modal-card-body">
      <div class="columns">
        <div class="column is-narrow">
          <b-field label="Dátum" label-position="on-border">
            <b-datepicker v-model="date" size="is-small" inline> </b-datepicker>
          </b-field>
        </div>
        <div class="column has-text-centered">
          <h3>Időtartam</h3>
          <div class="my-2">
            <b-button
              type="is-info"
              class="duration-button"
              @click="duration = 30"
              >30m</b-button
            >
            <b-button
              type="is-info"
              class="duration-button"
              @click="duration = 60"
              >1h</b-button
            >
            <b-button
              type="is-info"
              class="duration-button"
              @click="duration = 90"
              >1h 30m</b-button
            >
            <b-button
              type="is-info"
              class="duration-button"
              @click="duration = 120"
              >2h</b-button
            >
            <b-button
              type="is-info"
              class="duration-button"
              @click="duration = 240"
              >4h</b-button
            >
            <b-button
              type="is-info"
              class="duration-button"
              @click="duration = 480"
              >8h</b-button
            >
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Óra">
                <b-numberinput
                  v-model="durationHours"
                  min="0"
                  max="99"
                  class="duration-number"
                >
                </b-numberinput>
              </b-field>
              <b-field label="Perc">
                <b-numberinput
                  v-model="durationMinutes"
                  :min="duration === 0 ? 0 : -10"
                  max="70"
                  step="10"
                  min-step="1"
                  class="duration-number"
                >
                </b-numberinput>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Kezdés időpontja">
                <b-timepicker v-model="start" :key="startKey" inline>
                </b-timepicker>
              </b-field>
              <b-field label="Befejezés időpontja">
                <b-timepicker v-model="end" inline> </b-timepicker>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="columns">
          <div class="column is-one-quarter">
            <b-field label="Kliens" label-position="on-border">
              <b-input
                placeholder="pl.: LogiNet Systems Kft."
                type="search"
                icon="search"
                v-model="clientSearch"
              >
              </b-input>
            </b-field>
            <b-field>
              <b-dropdown
                inline
                scrollable
                expanded
                max-height="173px"
                v-model="selectedClient"
              >
                <b-dropdown-item
                  v-if="filteredClients.length === 0"
                  disabled
                  class="has-text-centered"
                >
                  Nincs találat!
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="client in filteredClients"
                  :value="client"
                  :key="client.id"
                  aria-role="listitem"
                  >{{ client.name }}</b-dropdown-item
                >
              </b-dropdown>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Projekt" label-position="on-border">
              <b-input
                placeholder="pl.: Internal portal"
                type="search"
                icon="search"
                v-model="projectSearch"
              >
              </b-input>
            </b-field>
            <b-field>
              <b-dropdown
                inline
                scrollable
                expanded
                max-height="173px"
                v-model="selectedProject"
              >
                <b-dropdown-item
                  v-if="filteredProjects.length === 0"
                  disabled
                  class="has-text-centered"
                >
                  Nincs találat!
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="project in filteredProjects"
                  :value="project"
                  :key="project.id"
                  aria-role="listitem"
                  >{{ project.name }}</b-dropdown-item
                >
              </b-dropdown>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Jegy" label-position="on-border">
              <b-input
                placeholder="pl.: LIP-371"
                type="search"
                icon="search"
                v-model="issueSearch"
              >
              </b-input>
            </b-field>
            <b-field>
              <b-dropdown
                inline
                scrollable
                expanded
                max-height="173px"
                v-model="selectedIssue"
              >
                <b-dropdown-item
                  v-if="issueSearch.length < 3 && selectedProject == null"
                  disabled
                  class="has-text-centered"
                >
                  A jegyek lekérdezéséhez kezdj el gépelni <br />a keresőbe vagy
                  válassz egy projektet!
                </b-dropdown-item>
                <b-dropdown-item
                  v-else-if="filteredIssues.length === 0"
                  disabled
                  class="has-text-centered"
                >
                  Nincs találat!
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="issue in filteredIssues"
                  :value="issue.key"
                  :key="issue.key"
                  aria-role="listitem"
                  >{{ issue.key }}: {{ issue.name }}</b-dropdown-item
                >
              </b-dropdown>
            </b-field>
          </div>
          <div class="column is-narrow px-0">
            <b-button
              type="is-danger"
              @click="deleteSelected"
              :disabled="!selectedClient && !selectedProject && !selectedIssue"
              icon-left="xmark"
              title="Kijelölés törlése"
              class="mr-2"
            >
            </b-button>
          </div>
        </div>
        <!--        <div class="mb-4">-->
        <!--          <span class="title is-5">Ajánlott jegyek:</span>-->
        <!--          <b-button-->
        <!--            v-for="issue in issues('recommended')"-->
        <!--            @click="selectedIssue = issue.key"-->
        <!--            :key="issue.key"-->
        <!--            type="is-info"-->
        <!--            class="mx-2"-->
        <!--            style="vertical-align: baseline"-->
        <!--          >-->
        <!--            {{ issue.key }}: {{ issue.name }}-->
        <!--          </b-button>-->
        <!--        </div>-->
        <div class="columns">
          <div class="column">
            <b-field label="Megjegyzés" label-position="on-border">
              <b-input
                v-model="comment"
                type="textarea"
                max-length="32767"
              ></b-input>
            </b-field>
          </div>
          <div class="column has-text-centered mt-auto">
            <div>
              <b-field label="Túlóra típusa" label-position="on-border">
                <b-select
                  placeholder="Kérlek válassz"
                  v-model="selectedOvertime"
                  expanded
                >
                  <option :value="overtimeType.NONE">Nincs túlóra</option>
                  <option
                    v-for="(option, index) in overtimeType.ENUM"
                    :value="option"
                    :key="option"
                  >
                    {{ $tf(overtimeType.LABEL[index]) }}
                  </option>
                </b-select>
                <b-tooltip
                  class="my-auto mx-2"
                  label="Csak elrendelt túlóra jelölendő annak!"
                  type="is-danger"
                >
                  <b-icon
                    v-if="selectedOvertime !== overtimeType.NONE"
                    icon="exclamation-circle"
                    type="is-danger"
                  ></b-icon>
                </b-tooltip>
              </b-field>
            </div>
          </div>
          <div class="column has-text-right mt-auto">
            <!--            <b-button-->
            <!--              type="is-info"-->
            <!--              @click="send(true)"-->
            <!--              :disabled="!selectedIssue"-->
            <!--              class="mx-2"-->
            <!--            >-->
            <!--              Mentés és új-->
            <!--            </b-button>-->
            <b-button
              type="is-info"
              @click="send(false)"
              :disabled="!selectedIssue"
            >
              Mentés
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import { OVERTIME_REQUEST_TYPE } from "@/utils/const";

export default {
  name: "EmployeeLogBar",
  data() {
    return {
      date: new Date(),
      start: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        8
      ),
      duration: 60,
      startKey: 0,
      selectedClient: null,
      clientSearch: "",
      selectedProject: null,
      projectSearch: "",
      selectedIssue: null,
      issueSearch: "",
      usedPrompts: [],
      loading: true,
      fetchedIssues: false,
      overtimeType: OVERTIME_REQUEST_TYPE,
      selectedOvertime: OVERTIME_REQUEST_TYPE.NONE,
      comment: null,
    };
  },
  computed: {
    end: {
      get() {
        return new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          this.date.getDate(),
          this.start.getHours() + this.durationHours,
          this.start.getMinutes() + this.durationMinutes
        );
      },
      set(value) {
        this.start.setHours(value.getHours() - this.durationHours);
        this.start.setMinutes(value.getMinutes() - this.durationMinutes);
        if (this.start.getDate() != value.getDate()) {
          if (value.getHours() === 0) {
            this.start = value;
          } else {
            this.start = new Date(value.setHours(value.getHours() - 1));
          }
          this.duration = 60;
        }
        this.startKey++;
      },
    },
    durationHours: {
      get() {
        return Math.floor(this.duration / 60);
      },
      set(value) {
        this.duration = value * 60 + (this.duration % 60);
      },
    },
    durationMinutes: {
      get() {
        return this.duration % 60;
      },
      set(value) {
        this.duration = Math.floor(this.duration / 60) * 60 + value;
      },
    },
    filteredClients() {
      let clients = this.clients || [];
      return clients.filter((element) => {
        let passesFilter = true;
        if (this.clientSearch) {
          passesFilter =
            element.name
              .toString()
              .toLowerCase()
              .indexOf(this.clientSearch.toLowerCase()) >= 0;
        }
        return passesFilter;
      });
    },
    filteredProjects() {
      let projects = this.projects || [];
      return projects.filter((element) => {
        let passesFilter = element.status === "ONGOING";
        // if (this.selectedClient) {
        //   passesFilter =
        //     passesFilter && element.client === this.selectedClient.id;
        // }
        if (this.projectSearch) {
          passesFilter =
            passesFilter &&
            element.name
              .toString()
              .toLowerCase()
              .indexOf(this.projectSearch.toLowerCase()) >= 0;
        }
        return passesFilter;
      });
    },
    filteredIssues() {
      let issues = [];
      if (this.selectedProject) {
        issues = issues.concat(this.issues(this.selectedProject.id));
      }
      // if (this.issues("recommended")) {
      //   issues = issues.concat(this.issues("recommended"));
      // }
      this.usedPrompts.forEach((prompt) => {
        issues = issues.concat(this.issues("FILTERED_" + prompt));
      });
      return issues.filter((element, ind) => {
        let passesFilter =
          issues.findIndex(
            (issue) => !!issue && !!element && issue.key === element.key
          ) === ind;
        if (this.issueSearch) {
          passesFilter =
            passesFilter &&
            (element.key + ": " + element.name)
              .toString()
              .toLowerCase()
              .indexOf(this.issueSearch.toLowerCase()) >= 0;
        }
        return passesFilter;
      });
    },
    ...mapGetters({
      clients: "enterprise_clients/clients",
      projects: "enterprise_core/limitedProjects",
      issues: "enterprise_core/issues",
      worklog: "worklog/myWorklog",
      profile: "census_user/profile",
    }),
  },
  async mounted() {
    if (localStorage.getItem("lastCacheDate")) {
      let lastCacheDate = new Date(localStorage.getItem("lastCacheDate"));
      let currentDate = new Date();
      if (
        currentDate.getDate() === lastCacheDate.getDate() &&
        currentDate.getMonth() === lastCacheDate.getMonth() &&
        currentDate.getFullYear() === lastCacheDate.getFullYear()
      ) {
        if (localStorage.getItem("defaultDate")) {
          this.date = new Date(localStorage.getItem("defaultDate"));
        }
        if (localStorage.getItem("defaultStart")) {
          this.start = new Date(localStorage.getItem("defaultStart"));
        }
        if (localStorage.getItem("defaultDuration")) {
          this.duration = Number(localStorage.getItem("defaultDuration"));
        }
      } else {
        localStorage.removeItem("defaultDate");
        localStorage.removeItem("defaultStart");
        localStorage.removeItem("defaultDuration");
      }
    }
    await this.$store.dispatch("enterprise_clients/fetchClients");
    await this.$store.dispatch("enterprise_core/fetchLimitedProjects");
    // await this.$store.dispatch(
    //   "enterprise_core/fetchRecommendedIssues",
    //   this.profile.externalId
    // );
    if (localStorage.getItem("defaultClient")) {
      this.selectedClient = this.clients.find(
        (c) => c.id === Number(localStorage.getItem("defaultClient"))
      );
    }
    if (localStorage.getItem("defaultProject")) {
      this.selectedProject = this.projects.find(
        (p) => p.id === Number(localStorage.getItem("defaultProject"))
      );
    }
    if (this.selectedProject) {
      await this.$store.dispatch(
        "enterprise_core/fetchNotDoneIssues",
        this.selectedProject.id
      );
      this.fetchedIssues = true;
    }
    if (localStorage.getItem("defaultIssue")) {
      this.selectedIssue = localStorage.getItem("defaultIssue");
    }
    this.loading = false;
  },
  watch: {
    date() {
      localStorage.setItem("defaultDate", this.date.toString());
      localStorage.setItem("lastCacheDate", new Date().toString());
    },
    start() {
      localStorage.setItem("defaultStart", this.start.toString());
      localStorage.setItem("lastCacheDate", new Date().toString());
    },
    duration(newDuration) {
      if (this.duration + newDuration < 0) {
        this.duration = 0;
      }
      localStorage.setItem("defaultDuration", this.duration);
      localStorage.setItem("lastCacheDate", new Date().toString());
    },
    selectedClient(newClient) {
      if (newClient != null) {
        if (!!this.selectedClient) {
          if (
            this.selectedProject &&
            this.selectedProject.client !== newClient.id
          ) {
            this.selectedProject = null;
            this.selectedIssue = null;
            localStorage.removeItem("defaultProject");
            localStorage.removeItem("defaultIssue");
          }
        }
        localStorage.setItem("defaultClient", newClient.id);
        this.$store.dispatch(
          "enterprise_core/fetchProjectsForClient",
          newClient.id
        );
      }
    },
    async selectedProject(newProject) {
      if (newProject != null) {
        if (this.selectedProject) {
          if (this.selectedIssue && this.selectedProject != newProject) {
            this.selectedIssue = null;
            localStorage.removeItem("defaultIssue");
          }
        }
        if (
          !this.selectedClient ||
          this.selectedClient.id !== newProject.client
        ) {
          this.selectedClient = this.clients.find(
            (c) => c.id === newProject.client
          );
        }
        localStorage.setItem("defaultProject", newProject.id);
        await this.$store.dispatch(
          "enterprise_core/fetchNotDoneIssues",
          newProject.id
        );
        this.fetchedIssues = true;
      }
    },
    selectedIssue(newIssue) {
      if (newIssue != null) {
        if (
          !this.selectedProject ||
          !this.selectedProject.identifier.startsWith(
            newIssue.substring(0, newIssue.indexOf("-"))
          )
        ) {
          this.selectedProject = this.projects.find((p) =>
            p.identifier.startsWith(
              newIssue.substring(0, newIssue.indexOf("-"))
            )
          );
        }
        localStorage.setItem("defaultIssue", newIssue);
      }
    },
    async issueSearch() {
      if (
        this.issueSearch.length > 2 &&
        !this.usedPrompts.some((prompt) =>
          this.issueSearch.startsWith(prompt)
        ) &&
        !this.selectedProject &&
        this.issues("FILTERED_" + this.issueSearch) == null
      ) {
        this.usedPrompts.push(this.issueSearch);
        await this.$store.dispatch(
          "enterprise_core/fetchFilteredIssues",
          this.issueSearch
        );
        this.fetchedIssues = true;
      }
    },
  },
  methods: {
    deleteSelected() {
      this.selectedClient = null;
      this.selectedProject = null;
      this.selectedIssue = null;
      this.$store.dispatch("enterprise_core/fetchLimitedProjects");
    },
    async send(andNew) {
      let request = {
        timeSpentSeconds: this.duration * 60,
        comment: this.comment,
        issueKey: this.selectedIssue,
        date: new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          this.date.getDate(),
          this.start.getHours(),
          this.start.getMinutes()
        ),
        overtime:
          this.selectedOvertime === this.overtimeType.NONE
            ? undefined
            : this.selectedOvertime,
      };
      this.$store
        .dispatch("worklog/postWorklog", request)
        .then(() => {
          this.$buefy.toast.open({
            duration: 2000,
            message: `Sikeres mentés`,
            position: "is-bottom",
            type: "is-success",
          });
        })
        .catch((reason) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `<strong>Sikertelen mentés</strong> - ${reason?.response?.data}`,
            position: "is-bottom",
            type: "is-danger",
          });
        });
      if (andNew) {
        this.selectedIssue = null;
        this.comment = null;
      } else {
        this.$emit("finished");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.duration-button {
  margin-bottom: 0.25em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  width: 5em;
}

.duration-number {
  margin: 0.25em;
}
a.dropdown-item.is-active {
  background-color: $blue;
}

.modal-body {
  overflow-y: auto !important;
  overflow-x: hidden;
  height: 100% !important;
}
</style>
