<template>
  <div class="modal-card logBarModal">
    <employee-log-bar :key="logBarKey" @finished="onFinish"></employee-log-bar>
  </div>
</template>

<script>
import EmployeeLogBar from "./EmployeeLogBar";
import { getCurrentInstance } from "vue";

export default {
  name: "EmployeeLogBarModal",
  components: { EmployeeLogBar },
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  data() {
    return {
      logBarKey: 0,
    };
  },
  computed: {},
  methods: {
    onFinish() {
      ++this.logBarKey;
      this.instance.emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.logBarModal {
  overflow-y: auto;
  width: 100%;
}
</style>
