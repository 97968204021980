<template>
  <section class="section">
    <div class="container">
      <h1 class="title">
        {{ $tf("userIndicatorList.title|Felhasználói attribútumok") }}
      </h1>
      <h2 class="subtitle">
        {{
          $tf(
            "userIndicatorList.subtitle|Felhasználó attribútumok létrehozása, szerkesztése"
          )
        }}
      </h2>

      <div class="is-pulled-top-right">
        <b-button
          type="is-info"
          class="is-pulled-right"
          @click="openEditPopup(null)"
          icon-left="plus-circle"
          >{{ $tf("userIndicatorList.new|Új felhasználói attribútum") }}
        </b-button>
      </div>
    </div>

    <div class="container">
      <b-tabs v-model="activeTab" :animated="false" type="is-toggle">
        <b-tab-item
          v-for="(key, value) in USER_INDICATOR_TYPE_LABEL"
          :key="value"
          :label="$tf(key)"
          :value="value"
        >
          <b-table
            :data="indicators.filter((indicator) => indicator.type === value)"
            ref="table"
            hoverable
            paginated
            per-page="15"
            :show-detail-icon="true"
          >
            <b-table-column
              field="name"
              :label="$tf('userIndicatorList.table.name|Megnevezés')"
              v-slot="props"
              sortable
              searchable
            >
              {{ props.row.name }}
            </b-table-column>

            <b-table-column
              field="actions"
              :label="$tf('userIndicatorList.table.actions|Műveletek')"
              centered
              v-slot="props"
              cell-class="do-not-wrap"
              width="32"
            >
              <clickable-icon
                portal-icon
                icon="pencil"
                type="is-info"
                class="mr-2"
                @click="openEditPopup(props.row)"
              ></clickable-icon>
              <clickable-icon
                portal-icon
                icon="trash"
                type="is-danger"
                @click="deleteRow(props.row)"
              ></clickable-icon>
            </b-table-column>
          </b-table>
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import {
  USER_INDICATOR_TYPE_ENUM,
  USER_INDICATOR_TYPE_LABEL,
} from "@/utils/const";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import { openDeleteConfirm } from "@/utils/util";
import UserIndicatorForm from "../../../components/census/user/UserIndicatorForm";

export default {
  name: "UserIndicatorList",
  components: { ClickableIcon },
  async mounted() {
    await this.fetchList();
  },
  data() {
    return {
      USER_INDICATOR_TYPE_LABEL,
      USER_INDICATOR_TYPE_ENUM,
      activeTab: USER_INDICATOR_TYPE_ENUM.DEPARTMENT,
    };
  },
  computed: {
    ...mapGetters({
      indicators: "user_indicator/indicators",
    }),
  },
  methods: {
    openEditPopup(row) {
      this.$buefy.modal.open({
        parent: this,
        component: UserIndicatorForm,
        props: { indicator: row, type: this.activeTab },
        hasModalCard: true,
        trapFocus: true,
      });
    },
    deleteRow(row) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("user_indicator/delete", row.id);
        await this.fetchList(true);
      });
    },
    async fetchList(force) {
      await this.$store.dispatch("user_indicator/fetch", force);
    },
  },
};
</script>

<style scoped lang="scss">
.button-container {
  display: flex;

  button {
    margin-left: auto;
  }
}

.tab-content {
  padding: 0 !important;
  padding-top: 2rem !important;
}
</style>
