<template>
  <section class="section">
    <b-modal v-model="newAssociation" @close="afterNewAssociation()">
      <div class="card p-4">
        <association-form
          :create="editAssociationData === null"
          :association-data="
            editAssociationData ? editAssociationData : newAssociationData
          "
          v-on:cancel="newAssociation = false"
          v-on:association-saved="afterNewAssociation"
        />
      </div>
    </b-modal>
    <div class="container">
      <div
        class="is-flex is-justify-content-space-between is-align-items-center"
      >
        <div>
          <h1 class="title">
            {{
              $tf("associationsList.title|Projekt - Kollega összerendelések")
            }}
          </h1>
          <h2 class="subtitle">
            {{
              $tf(
                "associationsList.subtitle|Összerendelések megtekintése, módosítása"
              )
            }}
          </h2>
        </div>
        <div>
          <b-field>
            <b-button type="is-info" @click="createAssociation">
              {{ $tf("associationList.create|Asszociáció hozzáadása") }}
            </b-button>
          </b-field>
        </div>
      </div>
    </div>
    <div class="container">
      <div v-if="loading">
        <LoadingSkeleton></LoadingSkeleton>
      </div>
      <div v-else>
        <b-field grouped>
          <b-field class="file is-primary">
            <b-upload
              v-model="file"
              v-if="canCreate"
              accept=".csv"
              @update:modelValue="uploadFile"
              :key="fileUploadKey"
            >
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">
                  {{
                    $tf(
                      "associationList.upload|Asszociációk importálása (.csv)"
                    )
                  }}
                </span>
              </span>
            </b-upload>
          </b-field>
          <b-field>
            <b-button type="is-info" @click="getExportCsv">
              {{ $tf("associationList.export|Asszociációk exportálása") }}
            </b-button>
          </b-field>
        </b-field>
        <b-table
          :data="associations"
          ref="table"
          striped
          hoverable
          mobile-cards
        >
          <b-table-column
            field="projectId"
            :label="$tf('association.table.project|Projekt')"
            centered
            v-slot="props"
          >
            <span>
              {{ projects.find(({ id }) => id === props.row.projectId).name }}
            </span>
          </b-table-column>
          <b-table-column
            field="employeeId"
            :label="$tf('association.table.employee|Alkalmazott')"
            centered
            v-slot="props"
          >
            <span>
              {{ employees.find(({ id }) => id === props.row.employeeId).name }}
            </span>
          </b-table-column>
          <b-table-column
            field="day"
            :label="$tf('association.table.date|Dátum')"
            centered
            v-slot="props"
          >
            <span>
              {{ props.row.day }}
            </span>
          </b-table-column>
          <b-table-column
            field="hours"
            :label="$tf('association.table.hours|Munkaórák')"
            centered
            v-slot="props"
          >
            <span>
              {{ props.row.hours }}
            </span>
          </b-table-column>
          <b-table-column
            field="note"
            :label="$tf('association.table.note|Megjegyzés')"
            centered
            v-slot="props"
          >
            <span>
              {{ props.row.note }}
            </span>
          </b-table-column>
          <b-table-column
            v-if="canEdit || canDelete"
            v-slot="props"
            centered
            field="actions"
            :label="$tf('association.table.actions|Műveletek')"
            width="80"
          >
            <b-tooltip
              v-if="canEdit"
              :label="$tf('association.table.actions.edit|Szerkesztés')"
              type="is-light"
            >
              <clickable-icon
                icon="edit"
                type="is-info"
                @click="editAssociation(props.row)"
              />
            </b-tooltip>
            <b-tooltip
              v-if="canDelete"
              :label="$tf('association.table.actions.delete|Törlés')"
              type="is-light"
            >
              <clickable-icon
                icon="trash"
                type="is-danger"
                @click="deleteAssociation(props.row)"
              />
            </b-tooltip>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton.vue";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import { PRIVILEGES } from "@/utils/const";
import { deepCopy } from "@/utils/util";
import AssociationForm from "@/components/ralloc/AssociationForm.vue";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "AssociationList",
  components: {
    AssociationForm,
    ClickableIcon,
    LoadingSkeleton,
  },
  setup: () => ({ v$: useVuelidate() }),

  async mounted() {
    await this.fetchAssociations();
    await this.$store.dispatch("employee/fetchColleagues");
    await this.$store.dispatch("enterprise_core/fetchProjects");
    this.loading = false;
  },

  data() {
    return {
      newAssociation: false,
      newAssociationData: {},
      editAssociationData: null,
      loading: true,
      file: undefined,
      fileUploadKey: 0,
    };
  },

  computed: {
    canCreate: function () {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.RALLOC.RESOURCE_ALLOCATION.CREATE,
        PRIVILEGES.RALLOC.RESOURCE_ALLOCATION.ADMIN
      );
    },
    canEdit: function () {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.RALLOC.RESOURCE_ALLOCATION.EDIT,
        PRIVILEGES.RALLOC.RESOURCE_ALLOCATION.ADMIN
      );
    },
    canDelete: function () {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.RALLOC.RESOURCE_ALLOCATION.DELETE,
        PRIVILEGES.RALLOC.RESOURCE_ALLOCATION.ADMIN
      );
    },
    ...mapGetters({
      associations: "association/associations",
      employees: "employee/employees",
      projects: "enterprise_core/projects",
    }),
  },
  methods: {
    async fetchAssociations(force) {
      await this.$store.dispatch("association/getAssociations", force);
    },
    async getExportCsv() {
      await this.$store.dispatch("association/getAssociationCsv");
    },
    createAssociation() {
      this.newTigData = {};
      this.newAssociation = true;
    },
    editAssociation(association) {
      this.editAssociationData = deepCopy(association);
      this.newAssociation = true;
    },
    async deleteAssociation(association) {
      this.$buefy.dialog.confirm({
        title: this.$tf("association.deleteModal.title|Allokáció törlése"),
        message: this.$tf(
          "association.deleteModal.message|Biztos, hogy kitörlöd a {name} Allokációját {day} napra?",
          {
            name: this.employees.find(({ id }) => id === association.employeeId)
              .name,
            day: association.day,
          }
        ),
        confirmText: this.$tf("association.deleteModal.confirm|Törlés"),
        cancelText: this.$tf("association.deleteModal.cancel|Mégsem"),
        trapFocus: true,
        type: "is-danger",
        onConfirm: async () => {
          await this.$store.dispatch(
            "association/deleteAssociation",
            association.id
          );
          await this.fetchAssociations(true);
        },
      });
    },
    async uploadFile(file) {
      if (file) {
        const formData = new FormData();
        formData.set("file", file);
        await this.$store.dispatch("association/uploadFile", formData);
        this.file = undefined;
        this.fileUploadKey++;
        await this.fetchAssociations(true);
      }
    },
    async afterNewAssociation() {
      this.newAssociation = false;
      this.editAssociationData = null;
      await this.fetchAssociations(true);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";
</style>
