<template>
  <main>
    <div class="modal-card-head">
      <h1 class="title has-text-centered">
        {{ $tf("selfTracking.logbar.title|Munkaidő rögzítése") }}
      </h1>
    </div>
    <div class="modal-card-body">
      <div class="columns">
        <div class="column is-narrow">
          <b-field
            :label="$tf('selfTracking.logbar.form.date|Dátum')"
            label-position="on-border"
          >
            <b-datepicker v-model="date" size="is-small" inline> </b-datepicker>
          </b-field>
        </div>
        <div class="column has-text-centered">
          <h3>{{ $tf("selfTracking.logbar.form.time|Időtartam") }}</h3>
          <div class="my-2">
            <b-button
              type="is-info"
              class="duration-button"
              @click="duration = 30"
              >30m</b-button
            >
            <b-button
              type="is-info"
              class="duration-button"
              @click="duration = 60"
              >1h</b-button
            >
            <b-button
              type="is-info"
              class="duration-button"
              @click="duration = 90"
              >1h 30m</b-button
            >
            <b-button
              type="is-info"
              class="duration-button"
              @click="duration = 120"
              >2h</b-button
            >
            <b-button
              type="is-info"
              class="duration-button"
              @click="duration = 240"
              >4h</b-button
            >
            <b-button
              type="is-info"
              class="duration-button"
              @click="duration = 480"
              >8h</b-button
            >
          </div>
          <div class="columns">
            <div class="column">
              <b-field :label="$tf('selfTracking.logbar.form.hour|Óra')">
                <b-numberinput
                  v-model="durationHours"
                  min="0"
                  max="99"
                  class="duration-number"
                >
                </b-numberinput>
              </b-field>
              <b-field :label="$tf('selfTracking.logbar.form.minute|Perc')">
                <b-numberinput
                  v-model="durationMinutes"
                  :min="duration === 0 ? 0 : -10"
                  max="70"
                  step="10"
                  min-step="1"
                  class="duration-number"
                >
                </b-numberinput>
              </b-field>
            </div>
            <div class="column">
              <b-field
                :label="
                  $tf('selfTracking.logbar.form.started|Kezdés időpontja')
                "
              >
                <b-timepicker v-model="start" :key="startKey" inline>
                </b-timepicker>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="columns">
          <div class="column is-one-third">
            <b-field
              :label="$tf('selfTracking.logbar.form.project|Projekt')"
              label-position="on-border"
            >
              <b-input
                placeholder="pl.: Internal portal"
                type="search"
                icon="search"
                v-model="projectSearch"
              >
              </b-input>
            </b-field>
            <b-field>
              <b-dropdown
                inline
                scrollable
                expanded
                max-height="173px"
                v-model="selectedProject"
              >
                <b-dropdown-item
                  v-if="filteredProjects.length === 0"
                  disabled
                  class="has-text-centered"
                >
                  Nincs találat!
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="project in filteredProjects"
                  :value="project"
                  :key="project.id"
                  aria-role="listitem"
                  >{{ project.name }}</b-dropdown-item
                >
              </b-dropdown>
            </b-field>
          </div>
          <!--          <div class="column is-one-third">-->
          <!--            <b-field-->
          <!--              :label="$tf('selfTracking.logbar.form.tig|Mérföldkő / Aktivitás')"-->
          <!--              label-position="on-border"-->
          <!--            >-->
          <!--              <b-input-->
          <!--                placeholder="pl.: Internal portal"-->
          <!--                type="search"-->
          <!--                icon="search"-->
          <!--                v-model="tigSearch"-->
          <!--              >-->
          <!--              </b-input>-->
          <!--            </b-field>-->
          <!--            <b-field>-->
          <!--              <b-dropdown-->
          <!--                inline-->
          <!--                scrollable-->
          <!--                expanded-->
          <!--                max-height="173px"-->
          <!--                v-model="selectedTig"-->
          <!--              >-->
          <!--                <b-dropdown-item-->
          <!--                  v-if="filteredTigs.length === 0"-->
          <!--                  disabled-->
          <!--                  class="has-text-centered"-->
          <!--                >-->
          <!--                  Nincs találat!-->
          <!--                </b-dropdown-item>-->
          <!--                <b-dropdown-item-->
          <!--                  v-for="tig in filteredTigs"-->
          <!--                  :value="tig"-->
          <!--                  :key="tig.id"-->
          <!--                  aria-role="listitem"-->
          <!--                  >{{ tig.name }}</b-dropdown-item-->
          <!--                >-->
          <!--              </b-dropdown>-->
          <!--            </b-field>-->
          <!--          </div>-->
          <div class="column is-one-third">
            <b-field
              :label="$tf('selfTracking.logbar.form.issue|Jegy')"
              label-position="on-border"
            >
              <b-input
                placeholder="pl.: LIP-371"
                type="search"
                icon="search"
                v-model="issueSearch"
              >
              </b-input>
            </b-field>
            <b-field>
              <b-dropdown
                inline
                scrollable
                expanded
                max-height="173px"
                v-model="selectedIssue"
              >
                <b-dropdown-item
                  v-if="filteredIssues.length === 0"
                  disabled
                  class="has-text-centered"
                >
                  Nincs találat!
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="issue in filteredIssues"
                  :value="issue"
                  :key="issue.key"
                  aria-role="listitem"
                  >{{ issue.key }}: {{ issue.name }}</b-dropdown-item
                >
              </b-dropdown>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field
              :label="$tf('selfTracking.logbar.form.comment|Megjegyzés')"
              label-position="on-border"
            >
              <b-input
                v-model="comment"
                type="textarea"
                max-length="32767"
              ></b-input>
            </b-field>
          </div>
          <div class="column has-text-centered mt-auto">
            <b-field
              :label="$tf('selfTracking.logbar.form.overtime|Túlóra típusa')"
              label-position="on-border"
            >
              <b-select
                placeholder="Kérlek válassz"
                v-model="selectedOvertime"
                expanded
              >
                <option
                  v-for="(option, index) in overtimeType.ENUM"
                  :value="option"
                  :key="option"
                >
                  {{ $tf(overtimeType.LABEL[index]) }}
                </option>
              </b-select>
              <b-tooltip
                class="my-auto mx-2"
                :label="
                  $tf(
                    'selfTracking.logbar.form.overtime.warning|Csak elrendelt túlóra jelölendő így!'
                  )
                "
                type="is-danger"
              >
                <b-icon
                  v-if="selectedOvertime !== overtimeType.ENUM.NONE"
                  icon="exclamation-circle"
                  type="is-danger"
                ></b-icon>
              </b-tooltip>
            </b-field>
          </div>
          <div class="column has-text-right mt-auto">
            <b-button type="is-info" @click="send" :disabled="!selectedIssue">
              {{ $tf("selfTracking.logbar.button.save|Bejegyzés létrehozása") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import { OVERTIME_REQUEST_TYPE } from "@/utils/const";

export default {
  name: "SelfTrackingLogBar",
  data() {
    return {
      date: new Date(),
      start: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        8
      ),
      duration: 60,
      startKey: 0,
      projectSearch: "",
      selectedProject: null,
      tigSearch: "",
      selectedTig: null,
      issueSearch: "",
      selectedIssue: null,
      loading: true,
      fetchedIssues: false,
      overtimeType: OVERTIME_REQUEST_TYPE,
      selectedOvertime: OVERTIME_REQUEST_TYPE.ENUM.NONE,
      comment: null,
    };
  },
  computed: {
    end: {
      get() {
        return new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          this.date.getDate(),
          this.start.getHours() + this.durationHours,
          this.start.getMinutes() + this.durationMinutes
        );
      },
      set(value) {
        this.start.setHours(value.getHours() - this.durationHours);
        this.start.setMinutes(value.getMinutes() - this.durationMinutes);
        if (this.start.getDate() !== value.getDate()) {
          if (value.getHours() === 0) {
            this.start = value;
          } else {
            this.start = new Date(value.setHours(value.getHours() - 1));
          }
          this.duration = 60;
        }
        this.startKey++;
      },
    },
    durationHours: {
      get() {
        return Math.floor(this.duration / 60);
      },
      set(value) {
        this.duration = value * 60 + (this.duration % 60);
      },
    },
    durationMinutes: {
      get() {
        return this.duration % 60;
      },
      set(value) {
        this.duration = Math.floor(this.duration / 60) * 60 + value;
      },
    },
    filteredProjects() {
      let projects = this.projects || [];
      return projects.filter((element) => {
        let passesFilter =
          element.status !== "CLOSED" &&
          element.status !== "CANCELLED" &&
          element.status !== "DENIED";
        if (this.projectSearch) {
          passesFilter =
            passesFilter &&
            element.name
              .toString()
              .toLowerCase()
              .indexOf(this.projectSearch.toLowerCase()) >= 0;
        }
        return passesFilter;
      });
    },
    filteredTigs() {
      let tigs = this.tigs || [];
      return tigs.filter((element) => {
        let passesFilter =
          element.status !== "COMPLETED" && element.status !== "CANCELLED";
        if (this.projectSearch) {
          passesFilter =
            passesFilter &&
            element.name
              .toString()
              .toLowerCase()
              .indexOf(this.tigSearch.toLowerCase()) >= 0;
        }
        return passesFilter;
      });
    },
    filteredIssues() {
      let issues = [];
      if (this.selectedProject) {
        issues = issues.concat(this.issues(this.selectedProject.id));
      }
      return issues.filter((element, ind) => {
        let passesFilter =
          issues.findIndex(
            (issue) => !!issue && !!element && issue.key === element.key
          ) === ind;
        if (this.issueSearch) {
          passesFilter =
            passesFilter &&
            (element.key + ": " + element.name)
              .toString()
              .toLowerCase()
              .indexOf(this.issueSearch.toLowerCase()) >= 0;
        }
        return passesFilter;
      });
    },
    ...mapGetters({
      projects: "enterprise_core/limitedProjects",
      issues: "enterprise_core/issues",
      worklog: "worklog/myWorklog",
      profile: "census_user/profile",
    }),
  },
  async mounted() {
    if (localStorage.getItem("lastCacheDate")) {
      let lastCacheDate = new Date(localStorage.getItem("lastCacheDate"));
      let currentDate = new Date();
      if (
        currentDate.getDate() === lastCacheDate.getDate() &&
        currentDate.getMonth() === lastCacheDate.getMonth() &&
        currentDate.getFullYear() === lastCacheDate.getFullYear()
      ) {
        if (localStorage.getItem("defaultDate")) {
          this.date = new Date(localStorage.getItem("defaultDate"));
        }
        if (localStorage.getItem("defaultStart")) {
          this.start = new Date(localStorage.getItem("defaultStart"));
        }
        if (localStorage.getItem("defaultDuration")) {
          this.duration = Number(localStorage.getItem("defaultDuration"));
        }
      } else {
        localStorage.removeItem("defaultDate");
        localStorage.removeItem("defaultStart");
        localStorage.removeItem("defaultDuration");
      }
    }
    await this.$store.dispatch("enterprise_core/fetchLimitedProjects");
    if (localStorage.getItem("defaultProject")) {
      this.selectedProject = this.projects.find(
        (p) => p.id === Number(localStorage.getItem("defaultProject"))
      );
    }
    if (this.selectedProject) {
      await this.$store.dispatch(
        "enterprise_core/fetchNotDoneIssues",
        this.selectedProject.id
      );
      this.fetchedIssues = true;
    }
    if (localStorage.getItem("defaultIssue")) {
      this.selectedIssue = localStorage.getItem("defaultIssue");
    }
    this.loading = false;
  },
  watch: {
    date() {
      localStorage.setItem("defaultDate", this.date.toString());
      localStorage.setItem("lastCacheDate", new Date().toString());
    },
    start() {
      localStorage.setItem("defaultStart", this.start.toString());
      localStorage.setItem("lastCacheDate", new Date().toString());
    },
    duration(newDuration) {
      if (this.duration + newDuration < 0) {
        this.duration = 0;
      }
      localStorage.setItem("defaultDuration", this.duration);
      localStorage.setItem("lastCacheDate", new Date().toString());
    },
    async selectedProject(newProject) {
      if (newProject != null) {
        if (this.selectedProject) {
          if (this.selectedIssue && this.selectedProject !== newProject) {
            this.selectedIssue = null;
            localStorage.removeItem("defaultIssue");
          }
        }
        localStorage.setItem("defaultProject", newProject.id);
        await this.$store.dispatch(
          "enterprise_core/fetchNotDoneIssues",
          newProject.id
        );
        this.fetchedIssues = true;
      }
    },
  },
  methods: {
    deleteSelected() {
      this.selectedProject = null;
      this.selectedIssue = null;
      this.$store.dispatch("enterprise_core/fetchLimitedProjects");
    },
    async send() {
      let request = {
        timeSpentSeconds: this.duration * 60,
        comment: this.comment,
        issueKey: this.selectedIssue.key,
        issueId: this.selectedIssue.id,
        date: new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          this.date.getDate(),
          this.start.getHours(),
          this.start.getMinutes()
        ),
        overtime:
          this.selectedOvertime === this.overtimeType.ENUM.NONE
            ? undefined
            : this.selectedOvertime,
      };
      this.$store
        .dispatch("worklog/postWorklog", request)
        .then(() => {
          this.$buefy.toast.open({
            duration: 2000,
            message: `Sikeres mentés`,
            position: "is-bottom",
            type: "is-success",
          });
        })
        .catch((reason) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `<strong>Sikertelen mentés</strong> - ${reason?.response?.data}`,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.duration-button {
  margin-bottom: 0.25em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  width: 5em;
}

.duration-number {
  margin: 0.25em;
}
a.dropdown-item.is-active {
  background-color: $blue;
}

.modal-body {
  overflow-y: auto !important;
  overflow-x: hidden;
  height: 100% !important;
}
</style>
