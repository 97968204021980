<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 30rem">
      <section class="modal-card-body">
        <h1 class="title" v-if="isCreate">Új dimenzió érték létrehozása</h1>
        <h1 class="title" v-else>{{ dimensionValueName }} módosítása</h1>

        <form @submit.prevent="submit" class="mt-5">
          <b-field class="mb-1">
            <vuelidated-input
              v-model="editData.name"
              placeholder="Dimenzió érték megnevezése"
              label="Dimenzió érték"
              :validation-rule="v$.editData.name"
              label-position="on-border"
            />
          </b-field>

          <b-field label="Projektek">
            <b-taginput
              v-model="editData.projects"
              :data="filteredProjects"
              append-to-body
              autocomplete
              :allow-new="false"
              :open-on-focus="true"
              icon="list"
              field="name"
              placeholder="Projektek választása"
              @typing="filterProjects"
              label-position="on-border"
            >
            </b-taginput>
          </b-field>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close"> Mégse </b-button>
            <b-button native-type="submit" type="is-info" class="ml-2">
              Mentés
            </b-button>
          </b-field>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import { required, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { getCurrentInstance } from "vue";

export default {
  name: "ProjectDimensionValueForm",
  components: { VuelidatedInput },
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  props: {
    dimensionValue: {
      type: Object,
      required: false,
      default: null,
    },
    dimensionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dimensionValueName: "",
      editData: {},
      isCreate: true,
      projects: [],
      filteredProjects: [],
    };
  },
  mounted() {
    this.$store.getters["enterprise_core/projects"].forEach((project) => {
      this.projects.push({
        identifier: project.identifier,
        name: project.name,
      });
    });

    if (this.dimensionValue) {
      this.isCreate = false;
      this.dimensionValueName = this.dimensionValue.name;
      this.editData = JSON.parse(JSON.stringify(this.dimensionValue));

      let selectedProjects = [];
      if (this.editData.projects) {
        this.editData.projects.forEach((dimValProject) => {
          selectedProjects.push(
            this.projects.find(
              (project) => project.identifier === dimValProject.identifier
            )
          );
        });
      }
      this.filteredProjects = this.projects;
      this.editData.projects = selectedProjects;
    } else {
      this.isCreate = true;
    }
  },
  validations: {
    editData: {
      name: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    afterSave() {
      this.close();
      this.$emit("after-save");
    },
    filterProjects(text) {
      this.filteredProjects = this.projects.filter((projects) => {
        return (
          projects.name.toString().toLowerCase().indexOf(text.toLowerCase()) >=
          0
        );
      });
    },
    submit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      if (this.isCreate) {
        this.editData.dimensionId = this.dimensionId;
        this.$store
          .dispatch("project_dimensions/createDimensionValue", this.editData)
          .then(() => {
            this.afterSave();
          });
      } else {
        this.$store
          .dispatch("project_dimensions/updateDimensionValue", this.editData)
          .then(() => {
            this.afterSave();
          });
      }
    },
  },
};
</script>
