<template>
  <div>
    <loading-skeleton v-if="loading" />
    <template v-else>
      <div class="project-form">
        <b-modal
          has-modal-card
          trap-focus
          aria-role="dialog"
          aria-modal
          v-model="commitmentModalOpen"
          v-on:cancel="commitmentEdit = null"
        >
          <div class="card p-4">
            <commitment-modal
              :edited-commitment="commitmentEdit"
              :project-commitment="projectEdit.commitment"
              :commitment-details-sum="commitmentDaysSum"
              :competencies="indicators.filter((i) => i.type === 'COMPETENCY')"
              :seniorities="indicators.filter((i) => i.type === 'SENIORITY')"
              :employees="employees"
              @add-commitment="(c) => addCommitmentDetail(c)"
            />
          </div>
        </b-modal>
        <div class="is-flex mb-3">
          <div>
            <h3 class="title">
              {{ $tf("projectForm.namesAndIds|Nevek és azonosítók") }}
              <b-tooltip
                :auto-close="['outside', 'escape']"
                :triggers="['click']"
                type="is-info"
                position="is-right"
              >
                <template v-slot:content>
                  <div class="content tooltip">
                    <p>
                      {{
                        $tf(
                          "projectForm.namesAndIds.tooltip|Az azonosítót kézzel szükséges kitölteni!"
                        )
                      }}
                    </p>
                  </div>
                </template>
                <portal-icon icon="info-circle" />
              </b-tooltip>
            </h3>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-third">
            <vuelidated-input
              v-model="projectEdit.name"
              :expanded="true"
              :validation-rule="v$.projectEdit.name"
              :label="$tf('projectForm.name|Név')"
              label-position="on-border"
              :placeholder="$tf('projectForm.name.placeholder|Név')"
            />
          </div>
          <div class="column is-one-third">
            <vuelidated-input
              v-model="projectEdit.identifier"
              :expanded="true"
              :validation-rule="v$.projectEdit.identifier"
              :label="$tf('projectForm.identifier|Azonosító')"
              label-position="on-border"
              :placeholder="$tf('projectForm.identifier.placeholder|Azonosító')"
            />
            <div class="column is-one-third" v-if="!isSelfTracking">
              <vuelidated-input
                v-model="projectEdit.prefix"
                :expanded="true"
                :validation-rule="v$.projectEdit.prefix"
                :label="$tf('projectForm.prefix|Prefix')"
                label-position="on-border"
                :placeholder="$tf('projectForm.prefix.placeholder|Prefix')"
              />
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half">
            <b-field
              :label="$tf('projectForm.manager|Felelős')"
              label-position="on-border"
            >
              <b-autocomplete
                v-model="managerName"
                :data="filteredLeads"
                clearable
                field="name"
                icon="search"
                :placeholder="
                  $tf('projectForm.manager.placeholder|pl. Elektrom Ágnes')
                "
                rounded
                @select="(option) => (projectEdit.lead = option.id)"
                class="has-blue-icon"
              >
                <template v-slot:empty>{{
                  $tf("projectForm.manager.empty|Nincs találat")
                }}</template>
              </b-autocomplete>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field
              :label="$tf('projectForm.client|Ügyfél')"
              label-position="on-border"
            >
              <b-autocomplete
                v-model="clientName"
                :data="filteredClients"
                clearable
                field="name"
                icon="search"
                :placeholder="
                  $tf('projectForm.client.placeholder|pl. Alpha Soft Kft.')
                "
                rounded
                @select="(option) => (projectEdit.client = option.id)"
                class="has-blue-icon"
              >
                <template v-slot:empty>{{
                  $tf("projectForm.client.empty|Nincs találat")
                }}</template>
              </b-autocomplete>
            </b-field>
          </div>
        </div>
      </div>
      <template v-if="projectEdit.type === 'BASIC'">
        <h3 class="title">
          {{ $tf("projectForm.finances.title|Pénzügyek") }}
          <b-tooltip
            :auto-close="['outside', 'escape']"
            :triggers="['click']"
            type="is-info"
            position="is-right"
          >
            <template v-slot:content>
              <div class="content tooltip">
                <p>
                  {{
                    $tf(
                      "projectForm.finances.subtitle|Megismerhető, hogy állunk anyagilag törzs szinten."
                    )
                  }}
                </p>
              </div>
            </template>
            <portal-icon icon="info-circle" />
          </b-tooltip>
        </h3>
        <div>
          <div
            class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
          >
            <div class="is-half-width">
              <div class="is-flex is-align-items-center">
                <b-tooltip
                  v-if="
                    projectData.budget !== projectEdit.budget &&
                    projectData.status !== 'REQUEST'
                  "
                  :label="
                    $tf(
                      'projectForm.protectedDataMustHaveComment|Védett adat módosításához megjegyzés kell!'
                    )
                  "
                  type="is-warning"
                  size="is-small"
                  multilined
                  ><b-icon
                    icon="triangle-exclamation"
                    type="is-warning"
                  ></b-icon
                ></b-tooltip>
                <money-input
                  v-model="projectEdit.budget"
                  :label="$tf('projectForm.budget|Büdzsé')"
                  label-position="on-border"
                  class="mb-2 ml-0 pr-0"
                  custom-class="is-full-width"
                  required
                />
              </div>

              <p class="money-subtext">
                {{ $tf("projectForm.calculatedProfit|Kalkulált profit:") }}
                <span>{{ projectEdit.calculatedProfit }}</span>
              </p>
            </div>
            <div class="is-half-width">
              <div class="is-flex is-align-items-center">
                <b-tooltip
                  v-if="
                    projectData.expectedIncome !== projectEdit.expectedIncome &&
                    projectData.status !== 'REQUEST'
                  "
                  :label="
                    $tf(
                      'projectForm.protectedDataMustHaveComment|Védett adat módosításához megjegyzés kell!'
                    )
                  "
                  type="is-warning"
                  size="is-small"
                  multilined
                  ><b-icon
                    icon="triangle-exclamation"
                    type="is-warning"
                  ></b-icon
                ></b-tooltip>
                <money-input
                  v-model="projectEdit.expectedIncome"
                  :label="$tf('projectForm.expectedIncome|Várható Bevétel:')"
                  label-position="on-border"
                  class="mb-2"
                  custom-class="is-full-width"
                  required
                />
              </div>
              <p class="money-subtext">
                {{
                  $tf("projectForm.calculatedProfitRate|Kalkulált profitráta:")
                }}
                <span>{{
                  percentify(projectEdit.calculatedProfitRate / 100)
                }}</span>
              </p>
            </div>
          </div>
          <div
            class="is-flex is-flex-wrap-wrap is-justify-content-space-between"
          >
            <div class="is-half-width">
              <div class="is-flex is-align-items-center">
                <b-tooltip
                  v-if="
                    projectData.commitment !== projectEdit.commitment &&
                    projectData.status !== 'REQUEST'
                  "
                  :label="
                    $tf(
                      'projectForm.protectedDataMustHaveComment|Védett adat módosításához megjegyzés kell!'
                    )
                  "
                  type="is-warning"
                  size="is-small"
                  multilined
                  ><b-icon
                    icon="triangle-exclamation"
                    type="is-warning"
                  ></b-icon
                ></b-tooltip>
                <money-input
                  v-model="projectEdit.commitment"
                  :label="
                    $tf('projectForm.commitment|Vállalás (előzetes becslés)')
                  "
                  day
                  label-position="on-border"
                  class="mb-2"
                  required
                />
                <b-collapse
                  style="min-width: 500px"
                  aria-id="commitment-collapse"
                  :open="false"
                  animation="slide"
                >
                  <template #trigger="props">
                    <div
                      class="card-header"
                      role="button"
                      :aria-controls="id"
                      :aria-expanded="props.open"
                      style="box-shadow: 0 0"
                    >
                      <div class="is-flex is-align-items-center pl-2">
                        <h1 class="title card-title">
                          <span style="font-size: 16px">{{
                            $tf(
                              "projectForm.commitment.droprown|Vállalás lebontása"
                            )
                          }}</span>
                        </h1>
                      </div>
                      <div class="ml-auto">
                        <a class="card-header-icon">
                          <b-icon
                            :icon="props.open ? 'chevron-up' : 'chevron-down'"
                          >
                          </b-icon>
                        </a>
                      </div>
                    </div>
                  </template>
                  <div class="mb-4">
                    <b-button
                      class="my-auto"
                      type="is-info"
                      @click="commitmentModalOpen = true"
                      icon-right="plus-circle"
                      >Vállalás hozzáadása</b-button
                    >
                  </div>
                  <div v-if="commitmentDetails?.length > 0">
                    <b-table :data="commitmentDetails">
                      <b-table-column
                        field="competence"
                        label="Kompetencia"
                        v-slot="props"
                      >
                        {{ props.row.competence.name }}
                      </b-table-column>
                      <b-table-column
                        field="competence"
                        label="Senioritás"
                        v-slot="props"
                      >
                        {{ props.row.seniority?.name ?? "N/A" }}
                      </b-table-column>
                      <b-table-column
                        field="workDays"
                        label="Vállalt napok"
                        v-slot="props"
                      >
                        {{ props.row.workDays }}
                      </b-table-column>
                      <b-table-column
                        field="actions"
                        :label="
                          $tf('projectpage.override.table.actions|Műveletek')
                        "
                        centered
                        v-slot="props"
                        width="80"
                      >
                        <b-tooltip
                          :label="
                            $tf(
                              'projectpage.override.table.actions.edit|Szerkesztés'
                            )
                          "
                          type="is-light"
                        >
                          <clickable-icon
                            icon="edit"
                            type="is-info"
                            @click="editCommitment(props.row)"
                          />
                        </b-tooltip>
                        <b-tooltip
                          :label="
                            $tf(
                              'projectpage.tigList.table.actions.delete|Törlés'
                            )
                          "
                          type="is-light"
                        >
                          <clickable-icon
                            icon="trash"
                            type="is-danger"
                            @click="
                              commitmentDetails = commitmentDetails.filter(
                                (c) => c !== props.row
                              )
                            "
                          />
                        </b-tooltip>
                      </b-table-column>
                    </b-table>
                    <div class="mt-4">
                      <b-button
                        class="is-block ml-auto"
                        type="is-info"
                        icon-right="save"
                        @click="updateCommitment"
                        >Lebontás mentése</b-button
                      >
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
          <br />

          <div class="is-flex is-flex-wrap-wrap info-boxes">
            <project-form-info-box
              :header="$tf('projectForm.check|Ellenőrzés')"
              :text="
                $tf(
                  'projectForm.checkByMilestonesSum|Mérföldkövek összeségében'
                )
              "
              has-borders
              type="is-light"
            />
            <project-form-info-box
              :header="
                $tf(
                  'projectForm.tigCommitmentSum|Mérföldkövek vállalásainak összege'
                )
              "
              :text="workdayify(tigSumCommitment)"
              has-borders
              :type="
                tigSumCommitment === projectData.commitment
                  ? 'is-success'
                  : 'is-warning'
              "
            />
            <project-form-info-box
              :header="
                $tf('projectForm.tigBudgetSum|Mérföldkövek büdzséinek összege')
              "
              :text="tigSumBudget"
              :mask="moneyify"
              has-borders
              :type="
                tigSumBudget === projectData.budget
                  ? 'is-success'
                  : 'is-warning'
              "
            />
            <project-form-info-box
              :header="
                $tf(
                  'projectForm.tigExpectedIncome|Mérföldkövek várható bevételeinek összege'
                )
              "
              :text="tigSumIncome"
              :mask="moneyify"
              has-borders
              :type="
                tigSumIncome === projectData.expectedIncome
                  ? 'is-success'
                  : 'is-warning'
              "
            />
            <project-form-info-box
              :header="
                $tf('projectForm.tigSumPercent|Mérföldkő teljesítési % összeg')
              "
              :text="tigSumPercent"
              suffix=" %"
              has-borders
              :type="tigSumPercent === 100 ? 'is-success' : 'is-warning'"
            />
          </div>
        </div>
      </template>
      <h3 class="title">
        {{ $tf("projectForm.dates.title|Időpontok") }}
        <b-tooltip
          :auto-close="['outside', 'escape']"
          :triggers="['click']"
          position="is-right"
          type="is-info"
        >
          <template v-slot:content>
            <div class="content tooltip">
              <p>
                {{
                  $tf(
                    "projectForm.dates.subtitle|Fontos időpontok a projekt folyamatában"
                  )
                }}
              </p>
            </div>
          </template>
          <portal-icon icon="info-circle" />
        </b-tooltip>
      </h3>
      <div>
        <template v-if="datesAreSet">
          <div class="is-flex is-flex-direction-column">
            <div
              class="is-flex is-flex-wrap-wrap is-justify-content-space-between"
            >
              <div class="is-half-width">
                <div class="is-flex is-align-items-center">
                  <b-field
                    :label="$tf('projectForm.dates.startDate|Kezdés dátuma')"
                    label-position="on-border"
                    class="datepicker-field has-blue-icon"
                    expanded
                    style="width: 100%"
                  >
                    <b-datepicker
                      :disabled="projectEdit.status !== 'REQUEST'"
                      v-model="projectEdit.startDate"
                      icon="calendar-day"
                      :placeholder="
                        $tf(
                          'projectForm.dates.startDate.placeholder|Kezdés dátuma'
                        )
                      "
                      trap-focus
                      class="has-blue-icon"
                      expanded
                    />
                  </b-field>
                </div>
              </div>
              <div class="is-half-width">
                <div class="is-flex is-align-items-center">
                  <b-tooltip
                    v-if="isDateChanged()"
                    :label="
                      $tf(
                        'projectForm.protectedDataMustHaveComment|Védett adat módosításához megjegyzés kell!'
                      )
                    "
                    type="is-warning"
                    size="is-small"
                    multilined
                    ><b-icon
                      icon="triangle-exclamation"
                      type="is-warning"
                    ></b-icon
                  ></b-tooltip>

                  <vuelidated-input
                    v-if="projectEdit.type === 'BASIC'"
                    type="date"
                    :validation-rule="v$.projectEdit.dueDate"
                    v-model="projectEdit.dueDate"
                    :placeholder="
                      $tf('projectForm.dates.dueDate.placeholder|Határidő')
                    "
                    :label="$tf('projectForm.dates.dueDate|Határidő')"
                    label-position="on-border"
                    icon="calendar-day"
                    class="datepicker-field has-blue-icon"
                    trap-focus
                    expanded
                  />
                </div>
              </div>
            </div>

            <div class="is-flex is-align-items-center">
              <b-field
                :label="$tf('projectForm.status|Státusz')"
                label-position="on-border"
                class="my-4"
                expanded
                style="width: 100%"
              >
                <b-select
                  :model-value="projectEdit.status"
                  :placeholder="$tf('projectForm.status.placeholder|Státusz')"
                  icon="signal"
                  class="has-blue-icon"
                  expanded
                  disabled
                >
                  <option value="REQUEST">
                    {{ $tf("projectForm.status.request|Kérelem") }}
                  </option>
                  <option value="CANCELLED">
                    {{ $tf("projectForm.status.cancelled|Visszavonva") }}
                  </option>
                  <option value="DENIED">
                    {{ $tf("projectForm.status.denied|Elutasítva") }}
                  </option>
                  <option value="APPROVED">
                    {{ $tf("projectForm.status.approved|Elfogadva") }}
                  </option>
                  <option value="ONGOING">
                    {{ $tf("projectForm.status.ongoing|Folyamatban") }}
                  </option>
                  <option value="DONE">
                    {{ $tf("projectForm.status.done|Kész") }}
                  </option>
                  <option value="CLOSED">
                    {{ $tf("projectForm.status.clodes|Lezárva") }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </template>

        <ProjectCollapse
          id="meta-data"
          :header="$tf('projectForm.misc.title|Egyéb kiegészítő adatok')"
        >
          <div class="content mt-5">
            <div>
              <div
                class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
              >
                <div class="is-half-width">
                  <b-field
                    :label="
                      $tf('projectForm.misc.managementType|Szervezés típusa')
                    "
                    label-position="on-border"
                  >
                    <b-select
                      v-model="projectEdit.managementType"
                      :placeholder="
                        $tf('projectForm.misc.managementType.placeholder|Típus')
                      "
                      icon="sitemap"
                      class="has-blue-icon"
                    >
                      <option value="Waterfall">
                        {{
                          $tf(
                            "projectForm.misc.managementType.waterfall|Vízesés"
                          )
                        }}
                      </option>
                      <option value="Agile">
                        {{
                          $tf("projectForm.misc.managementType.agile|Agilis")
                        }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <div
                class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
              >
                <div class="is-half-width">
                  <b-field
                    v-for="(dimension, index) of dimensions"
                    :key="index"
                    :label="dimension.dimensionName"
                    label-position="on-border"
                  >
                    <b-taginput
                      v-model="selectedDimensionValues[dimension.dimensionName]"
                      :data="filteredDimensions[dimension.dimensionName]"
                      autocomplete
                      :allow-new="false"
                      :open-on-focus="true"
                      icon="list"
                      field="name"
                      :placeholder="dimension.dimensionName + ' választása'"
                      @typing="
                        filterDimensionValues($event, dimension.dimensionName)
                      "
                      class="has-blue-icon"
                    >
                    </b-taginput>
                  </b-field>
                </div>
              </div>
              <div
                class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
              >
                <div class="is-half-width">
                  <b-field label="Színek" label-position="on-border" expanded>
                    <b-select
                      v-model="projectEdit.color"
                      placeholder="Szín"
                      icon="palette"
                      class="has-blue-icon"
                    >
                      <option
                        v-for="color in projectColors"
                        :key="color.id"
                        :value="color.id"
                      >
                        {{ color.colorName }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <div
                class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
              >
                <div class="is-half-width">
                  <b-field
                    :label="$tf('projectForm.misc.technologies|Technológiák')"
                    label-position="on-border"
                    expanded
                  >
                    <b-taginput
                      v-model="projectEdit.technologies"
                      :data="filteredTechnologies"
                      autocomplete
                      :allow-new="false"
                      :open-on-focus="true"
                      icon="microchip"
                      :placeholder="
                        $tf(
                          'projectForm.misc.technologies.placeholder|Technológiák választása'
                        )
                      "
                      @typing="filterTechnologies"
                      class="has-blue-icon"
                    >
                    </b-taginput>
                  </b-field>
                </div>
                <div class="is-half-width">
                  <b-field
                    :label="$tf('projectForm.misc.laborTypes|Feladattípusok')"
                    label-position="on-border"
                    expanded
                  >
                    <b-taginput
                      v-model="projectEdit.laborTypes"
                      :data="filteredLaborTypes"
                      autocomplete
                      :allow-new="false"
                      :open-on-focus="true"
                      icon="users-cog"
                      :placeholder="
                        $tf(
                          'projectForm.misc.laborTypes.placeholder|Feladattípusok választása'
                        )
                      "
                      @typing="filterLaborTypes"
                      class="has-blue-icon"
                    >
                    </b-taginput>
                  </b-field>
                </div>
              </div>
              <b-field
                :label="$tf('projectForm.misc.summary|Összefoglaló, leírás')"
                label-position="on-border"
                expanded
              >
                <b-input
                  type="textarea"
                  v-model="projectEdit.summary"
                ></b-input>
              </b-field>
              <b-field
                :label="
                  $tf(
                    'projectForm.misc.supportPotential|CR, support és upsell lehetőségek'
                  )
                "
                label-position="on-border"
                expanded
              >
                <b-input
                  type="textarea"
                  v-model="projectEdit.supportPotential"
                ></b-input>
              </b-field>
              <h3 class="title">
                {{ $tf("projectForm.attachments.title|Csatolmányok") }}
                <b-tooltip
                  :auto-close="['outside', 'escape']"
                  :triggers="['click']"
                  type="is-info"
                  position="is-right"
                >
                  <template v-slot:content>
                    <div class="content tooltip">
                      <p>
                        {{
                          $tf(
                            "projectForm.attachments.subtitle|Szerződések, specifikációk, egyéb dokumentumok."
                          )
                        }}
                      </p>
                    </div>
                  </template>
                  <portal-icon icon="info-circle" />
                </b-tooltip>
              </h3>
              <h2 class="subtitle">
                {{
                  $tf(
                    "projectForm.attachments.subtitle|Szerződések, specifikációk, becslések, egyéb projekthez kapcsolódó dokumentumok feltöltése."
                  )
                }}
              </h2>
              <div
                class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
              >
                <MultifileUpload v-model="uploadedFiles" />
              </div>
              <b-table :data="projectData.documents || []">
                <b-table-column
                  field="displayName"
                  :label="$tf('projectForm.attachments.table.file|Fájl')"
                  v-slot="props"
                >
                  {{ props.row.displayName }}
                </b-table-column>
                <b-table-column
                  field="createdBy"
                  :label="
                    $tf('projectForm.attachments.table.uploader|Feltöltő')
                  "
                  v-slot="props"
                >
                  {{ employees.find((e) => e.id === props.row.createdBy).name }}
                </b-table-column>
                <b-table-column
                  field="created"
                  :label="
                    $tf(
                      'projectForm.attachments.table.uploadedAt|Feltöltés időpontja'
                    )
                  "
                  v-slot="props"
                >
                  {{ formatDate(props.row.created) }}
                </b-table-column>
                <b-table-column
                  :label="
                    $tf('projectForm.attachments.table.download|Letöltés')
                  "
                  cell-class="has-text-centered"
                  v-slot="props"
                  width="40"
                >
                  <a @click="downloadFile(props.row.id, props.row.displayName)"
                    ><b-icon icon="download"></b-icon
                  ></a>
                </b-table-column>
                <b-table-column
                  :label="$tf('projectForm.attachments.table.delete|Törlés')"
                  v-slot="props"
                  cell-class="has-text-centered"
                  width="40"
                >
                  <a @click="deleteFile(props.row.id)"
                    ><b-icon icon="trash" type="is-danger"></b-icon
                  ></a>
                </b-table-column>
              </b-table>
            </div>
          </div>
          <template #trigger="props">
            <div class="has-text-centered">
              <a class="link is-info">
                {{
                  !props.open
                    ? $tf("projectForm.attachments.metaData|Metaadatok")
                    : $tf("projectForm.attachments.close|Bezárás")
                }}
                <br />
                <b-icon
                  :icon="!props.open ? 'angle-down' : 'angle-up'"
                ></b-icon>
              </a>
            </div>
          </template>
        </ProjectCollapse>
        <div class="has-text-centered mt-5 mb-4 is-flex">
          <b-field class="ml-auto">
            <b-button
              v-if="projectEdit.type === 'BASIC'"
              class="is-info"
              @click="saveProject(true)"
            >
              {{
                $tf("projectForm.saveWithComment|Mentés megjegyzéssel")
              }}</b-button
            >
          </b-field>
          <b-field>
            <b-button class="is-info" @click="saveProject(false)">
              {{ $tf("projectForm.forward|Mentés") }}</b-button
            >
          </b-field>
        </div>
      </div></template
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, requiredIf } from "@vuelidate/validators";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import {
  deepCopy,
  formatDate,
  isNullOrUndefined,
  moneyify,
  numberify,
  percentify,
  workdayify,
} from "@/utils/util";
import PortalIcon from "@/components/module/icon/PortalIcon";
import MoneyInput from "@/components/module/input/MoneyInput";
import MultifileUpload from "@/components/module/input/MultifileUpload";
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton";
import { useVuelidate } from "@vuelidate/core";
import ProjectCollapse from "../module/ProjectCollapse";
import JournalProjectModal from "../journal/JournalProjectModal";
import ProjectFormInfoBox from "@/components/enterprise/project/module/ProjectFormInfoBox.vue";
import CommitmentModal from "@/components/enterprise/project/form/CommitmentModal.vue";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import { ISSUE_TRACKING_SYSTEM } from "@/utils/const";

export default {
  name: "ProjectEdit",
  components: {
    ClickableIcon,
    CommitmentModal,
    LoadingSkeleton,
    ProjectFormInfoBox,
    MoneyInput,
    MultifileUpload,
    VuelidatedInput,
    PortalIcon,
    ProjectCollapse,
  },
  setup: () => ({ v$: useVuelidate() }),
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: false,
    },
    projectType: {
      type: String,
      required: false,
      default: "BASIC",
    },
    employees: {
      type: Array,
      required: true,
    },
  },
  validations: {
    projectEdit: {
      identifier: {
        required,
      },
      name: {
        required,
      },
      prefix: {
        required,
      },
      lead: {
        required,
      },
      type: {
        required,
      },
      client: {
        required,
      },
      budget: {
        required: requiredIf(function () {
          return this.projectEdit.type === "BASIC";
        }),
      },
      expectedIncome: {
        required: requiredIf(function () {
          return this.projectEdit.type === "BASIC";
        }),
      },
      commitment: {
        required: requiredIf(function () {
          return this.projectEdit.type === "BASIC";
        }),
      },
      dueDate: {
        required: requiredIf(function () {
          return this.projectEdit.type === "BASIC";
        }),
      },
    },
  },
  data() {
    return {
      selectedDimensionValues: {},
      moneyify,
      numberify,
      percentify,
      workdayify,
      formatDate,
      datesAreSet: false,
      managerName: "",
      clientName: "",
      colorName: "",
      projectKey: "",
      importProject: false,
      syncKeys: false,
      syncKeyLoading: false,
      downloadKeyLoading: false,
      commitmentModalOpen: false,
      commitmentEdit: null,
      commitmentDetails: [],
      technologies: [
        "PHP",
        "Java",
        "HTML",
        "CSS",
        "Vue",
        "NodeJS",
        "iOS",
        "Android",
      ],
      filteredTechnologies: [
        "PHP",
        "Java",
        "HTML",
        "CSS",
        "Vue",
        "NodeJS",
        "iOS",
        "Android",
      ],
      laborTypes: [
        "Üzleti Igényfelmérés",
        "Specifikáció Írás",
        "Rendszertervezés",
        "Fejlesztés",
        "Integráció",
        "Tesztelés",
        "PM",
        "Telepítés",
        "UX design",
        "UI/GFX Design",
      ],
      filteredLaborTypes: [
        "Üzleti Igényfelmérés",
        "Specifikáció Írás",
        "Rendszertervezés",
        "Fejlesztés",
        "Integráció",
        "Tesztelés",
        "PM",
        "Telepítés",
        "UX design",
        "UI/GFX Design",
      ],
      dimensions: [],
      filteredDimensions: {},
      filteredColors: [],
      projectEdit: {
        internal: false,
      },
      uploadedFiles: [],
      loading: false,
    };
  },
  watch: {
    "projectEdit.budget": function (val) {
      let expIncome = numberify(this.projectEdit.expectedIncome);
      let calcProfit = expIncome - numberify(val);
      this.projectEdit.calculatedProfit = moneyify.call(this, calcProfit);
      this.projectEdit.calculatedProfitRate =
        Math.round((calcProfit / expIncome) * 10000) / 100;
    },
    "projectEdit.expectedIncome": function (val) {
      let expIncome = numberify(val);
      let calcProfit = expIncome - numberify(this.projectEdit.budget);
      this.projectEdit.calculatedProfit = moneyify.call(this, calcProfit);
      this.projectEdit.calculatedProfitRate =
        Math.round((calcProfit / expIncome) * 10000) / 100;
    },
  },
  computed: {
    isSelfTracking() {
      return this.issueTrackingSystem === ISSUE_TRACKING_SYSTEM.SELF.enum;
    },
    filteredLeads() {
      let leads = this.leads || [];
      return leads.filter((element) => {
        if (!this.managerName || !element.name || !element.username)
          return false;
        return (
          element.name
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.managerName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.username
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.managerName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
    filteredClients() {
      let clients = this.clients || [];
      return clients.filter((element) => {
        if (!this.clientName || !element.identifier) return false;
        return (
          element.identifier
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.clientName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.name
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.clientName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
    tigSumBudget() {
      let sumBudget = 0;
      if (this.projectData.tigDatas) {
        for (let tig of this.projectData.tigDatas) {
          sumBudget += tig.budget ? tig.budget : 0;
        }
      }
      return sumBudget;
    },
    tigSumCommitment() {
      let sumCommitment = 0;
      if (this.projectData.tigDatas) {
        for (let tig of this.projectData.tigDatas) {
          sumCommitment += tig.commitment ? parseFloat(tig.commitment) : 0;
        }
      }
      return sumCommitment;
    },
    tigSumIncome() {
      let sumIncome = 0;
      if (this.projectData.tigDatas) {
        for (let tig of this.projectData.tigDatas) {
          sumIncome += tig.billableAmount ? tig.billableAmount : 0;
        }
      }
      return sumIncome;
    },
    tigSumPercent() {
      let sumPercent = 0;
      if (this.projectData.tigDatas) {
        for (let tig of this.projectData.tigDatas) {
          sumPercent += tig.billablePercentage ? tig.billablePercentage : 0;
        }
      }
      return sumPercent;
    },
    commitmentDaysSum() {
      return (
        this.commitmentDetails
          ?.map((c) => c.workDays)
          .reduce((a, b) => parseInt(a) + parseInt(b), 0) ?? 0
      );
    },
    ...mapGetters({
      leads: "employee/leads",
      clients: "enterprise_clients/clients",
      projectColors: "project_dimensions/getProjectColors",
      indicators: "user_indicator/indicators",
      commitments: "commitment/commitments",
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),
  },
  methods: {
    async preloadData() {
      this.loading = true;
      this.projectEdit = deepCopy(this.projectData);
      this.projectEdit.startDate = new Date(this.projectEdit.startDate);
      if (this.projectEdit.dueDate) {
        this.projectEdit.dueDate = new Date(this.projectEdit.dueDate);
      }
      if (!this.projectEdit.type) {
        this.projectEdit.type = this.projectType;
      }

      this.projectKey = {
        key: this.projectEdit.key,
        used: true,
        $isDisabled: true,
      };

      const promises = [];
      promises.push(this.$store.dispatch("enterprise_clients/fetchClients"));
      promises.push(
        this.$store.dispatch("project_dimensions/fetchProjectColorsCompact")
      );
      promises.push(this.$store.dispatch("user_indicator/fetch"));
      promises.push(
        this.$store.dispatch("commitment/getCommitments", this.projectData.id)
      );
      promises.push(
        this.$store.dispatch(
          "project_dimensions/fetchActiveProjectDimensionsWithValues"
        )
      );
      promises.push(this.$store.dispatch("employee/fetchLeads"));

      await Promise.all(promises);

      this.datesAreSet = true;
      this.filterColors("");

      this.commitmentDetails =
        (this.commitments
          ? this.commitments[0]?.commitments.map((c) => {
              c.competence = this.indicators.find(
                (i) => i.id === c.competenceId
              );
              c.seniority = this.indicators.find((i) => i.id === c.seniorityId);
              return c;
            })
          : undefined) ?? [];

      this.dimensions =
        this.$store.getters[
          "project_dimensions/getProjectDimensionsWithValues"
        ];

      this.managerName = this.leads.filter(
        (c) => c.id === this.projectEdit.lead
      )[0].name;
      this.clientName = this.clients.filter(
        (c) => c.id === this.projectEdit.client
      )[0].name;

      if (this.projectEdit.dimensionValues) {
        this.projectEdit.dimensionValues.forEach((dimension) => {
          this.selectedDimensionValues[dimension.dimensionName] =
            dimension.values;
        });
      }

      this.dimensions.forEach((item) => {
        this.filteredDimensions[item.dimensionName] = item.values.slice();
      });
      this.loading = false;
    },
    async saveProject(withComment) {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        if (!withComment && this.projectData.status !== "REQUEST") {
          if (
            this.projectEdit.type === "BASIC" &&
            (this.projectData.budget !== this.projectEdit.budget ||
              this.projectData.expectedIncome !==
                this.projectEdit.expectedIncome ||
              this.projectData.commitment !== this.projectEdit.commitment ||
              this.projectData.dueDate !== formatDate(this.projectEdit.dueDate))
          ) {
            this.$buefy.toast.open({
              duration: 5000,
              message: this.$tf(
                "projectForm.protectedDataMustHaveComment|Védett adat módosításához megjegyzés kell!"
              ),
              position: "is-bottom",
              type: "is-warning",
            });
            withComment = true;
          }
        }

        if (withComment) {
          this.$buefy.modal.open({
            parent: this,
            component: JournalProjectModal,
            props: {
              projectData: this.projectData,
              projectEdit: this.projectEdit,
            },
            hasModalCard: true,
            trapFocus: true,
            width: "100%",
            events: {
              "project-saved": (comment) => {
                this.actuallySaveProject(comment);
              },
            },
          });
        } else {
          await this.actuallySaveProject();
        }
      }
    },
    getColorId(projectRequest) {
      if (projectRequest.colors && projectRequest.colors.length > 0) {
        const colorInHex = projectRequest.colors[0];
        const colorId = this.projectColors.find(
          (color) => color["colorInHex"] === colorInHex
        ).id;
        delete projectRequest.colors;
        return colorId;
      }
      return null;
    },
    async actuallySaveProject(comment) {
      let projectRequest = deepCopy(this.projectEdit);

      if (projectRequest.dueDate) {
        projectRequest.dueDate = formatDate(projectRequest.dueDate);
      }
      projectRequest.startDate = formatDate(projectRequest.startDate);
      projectRequest.starred = false;
      projectRequest.prefix = projectRequest.prefix.toUpperCase();
      // projectRequest.color = this.getColorId(projectRequest);

      projectRequest.dimensionValues = Object.values(
        this.selectedDimensionValues
      )
        .filter(Array.isArray) // Filter only properties that are arrays
        .flatMap((dimensionValues) =>
          dimensionValues.map((dimValue) => dimValue.id)
        );

      delete projectRequest.calculatedProfit;
      delete projectRequest.calculatedProfitRate;
      delete projectRequest.spent;
      delete projectRequest.expenseTotal;
      delete projectRequest.income;

      if (comment) {
        projectRequest.commentSummary = comment.summary;
        projectRequest.commentDescription = comment.description;
      }

      await this.$store
        .dispatch("enterprise_projects/saveProject", {
          projectData: projectRequest,
        })
        .then((value) => {
          this.$emit("project-saved", value ? projectRequest.key : null);
        });
    },
    filterLaborTypes(text) {
      this.filteredLaborTypes = this.laborTypes.filter((option) => {
        return option.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    filterTechnologies(text) {
      this.filteredTechnologies = this.technologies.filter((option) => {
        return option.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    filterColors(text) {
      this.filteredColors = this.projectColors
        .filter((option) => {
          return (
            option.colorInHex
              .toString()
              .toLowerCase()
              .indexOf(text.toLowerCase()) >= 0
          );
        })
        .map((option) => option.colorInHex);
    },
    filterDimensionValues(text, dimensionName) {
      const dimension = this.dimensions.find(
        (dimension) => dimension.dimensionName === dimensionName
      );
      const filteredValues = dimension.values.filter((dimensionValue) => {
        return (
          dimensionValue.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        );
      });

      this.filteredDimensions[dimensionName].splice(
        0,
        this.filteredDimensions[dimensionName].length
      );
      this.filteredDimensions[dimensionName].push(...filteredValues);
    },
    async selectProject(selectedOption) {
      this.loading = true;
      await this.$store.dispatch("enterprise_projects/fetchProjectData", {
        projectIdentifier: selectedOption.identifier,
        force: true,
      });
      this.projectEdit = this.$store.getters["enterprise_projects/projectData"](
        selectedOption.identifier
      );
      this.projectEdit.identifier = selectedOption.identifier;
      if (this.projectEdit.startDate) {
        this.projectEdit.startDate = new Date(this.projectEdit.startDate);
      } else this.projectEdit.startDate = new Date();
      if (this.projectEdit.endDate) {
        this.projectEdit.endDate = new Date(this.projectEdit.endDate);
      } else this.projectEdit.endDate = new Date();
      if (this.projectEdit.dueDate) {
        this.projectEdit.dueDate = new Date(this.projectEdit.dueDate);
      } else this.projectEdit.dueDate = new Date();
      this.loading = false;
    },
    toDate(date) {
      const numbers = date.split("-");
      return new Date(numbers[0], numbers[1] - 1, numbers[2]);
    },
    isDateChanged() {
      if (
        isNullOrUndefined(this.projectData.dueDate) ||
        isNullOrUndefined(this.projectEdit.dueDate)
      ) {
        return false;
      }
      return (
        (this.toDate(this.projectData.dueDate).getFullYear() !==
          this.projectEdit.dueDate.getFullYear() ||
          this.toDate(this.projectData.dueDate).getMonth() !==
            this.projectEdit.dueDate.getMonth() ||
          this.toDate(this.projectData.dueDate).getDate() !==
            this.projectEdit.dueDate.getDate()) &&
        this.projectData.status !== "REQUEST"
      );
    },
    downloadFile(id, fileName) {
      this.$store.dispatch("enterprise_projects/downloadProjectFile", {
        fileId: id,
        fileName: fileName,
      });
    },
    deleteFile(id) {
      this.$buefy.dialog.confirm({
        message: this.$tf(
          "projectForm.attachmentDeleteModal.message|Biztosan törölni szeretnéd a csatolmányt?"
        ),
        onConfirm: () => {
          if (
            this.$store.dispatch("enterprise_projects/deleteProjectFile", id)
          ) {
            // eslint-disable-next-line vue/no-mutating-props
            this.projectData.documents = this.projectData.documents.filter(
              (document) => document.id !== id
            );
            // Vue.set(
            //   this.projectData,
            //   "documents",
            //   this.projectData.documents.filter(
            //     (document) => document.id !== id
            //   )
            // );
          }
        },
      });
    },
    addCommitmentDetail(c) {
      this.commitmentDetails.push(c);
      this.commitmentModalOpen = false;
      if (this.commitmentDaysSum > (this.projectEdit.commitment ?? 0)) {
        this.projectEdit.commitment = this.commitmentDaysSum;
      }
    },
    editCommitment(c) {
      this.commitmentEdit = c;
      this.commitmentModalOpen = true;
    },
    updateCommitment() {
      this.$store.dispatch("commitment/updateCommitment", {
        projectId: this.projectData.id,
        commitments: this.commitmentDetails.map((c) => {
          return {
            competenceId: c.competence.id,
            seniorityId: c.seniority?.id,
            workDays: c.workDays,
          };
        }),
      });
    },
  },
  mounted() {
    this.preloadData();
  },
};
</script>

<style
  src="../../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
></style>

<style lang="scss">
@import "~@/assets/scss/colors";
.is-half-width {
  flex: 1;
  width: 50%;
  .select {
    select {
      width: 100%;
    }

    width: 100%;
  }
  @media screen and (max-width: 800px) {
    flex: 0 0 100%;
    margin-bottom: 20px;
    width: 100%;
  }
}
.is-full-width {
  .field-body {
    .field {
      .control {
        width: 100%;
      }
    }
  }
}
</style>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";
.money-subtext {
  color: $turquoise;
  font-weight: 400;
  span {
    color: $custom-dark-blue;
    font-weight: 500;
  }
}
.info-boxes {
  gap: 20px;
  @media screen and (max-width: 800px) {
    justify-content: center !important;
    * {
      width: 100%;
    }
  }
}
.title {
  margin-bottom: 8px;
}
.is-flex {
  gap: 10px;
}
.is-used-key {
  color: $red;
  font-weight: bold;
  cursor: not-allowed;
}
</style>
