<template>
  <div class="is-flex has-gap-4">
    <div class="is-flex-3">
      <b-field label-position="on-border" :label="$tf('editObject.label')">
        <multiselect-dropdown
          v-model="selectedItems"
          :items="currentValues"
          autocomplete
          :icon="editObject.icon"
          open-on-focus
          searchable
          scrollable
          append-to-body
          has-select-all-option
          :identifier-field="idField"
          :name-field="valueField"
          hide-selected
          expanded
        />
      </b-field>
    </div>
    <div class="is-flex-5 is-flex is-align-items-center has-gap-2">
      <div>
        <strong>{{ $tf("editObject.selectedLabel") }}</strong>
      </div>
      <div>
        <p>{{ arraySummary }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { FORECAST_RULES } from "@/utils/const";
import { deepCopy, generateArraySummaryTranslated } from "@/utils/util";
import { mapGetters } from "vuex";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";

export default {
  name: "ForecastSegmentFiltersEntry",
  components: { MultiselectDropdown },
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    this.editObject = deepCopy(this.initial);
    if (typeof this.editObject.value === "undefined") this.selectedItems = [];
    else {
      this.selectedItems = this.editObject.value
        .map((it) => {
          const item = this.currentValues.find(
            (val) => val[this.idField] === it
          );
          if (item) return item[this.idField];
          return undefined;
        })
        .filter((it) => it !== undefined);
    }
  },
  data() {
    return {
      editObject: {
        key: undefined,
        label: undefined,
        value: [],
      },
      FORECAST_RULES,
      selectedItems: [],
    };
  },
  computed: {
    ...mapGetters(
      FORECAST_RULES.map((rule) => {
        return {
          [rule.enum]: rule.get.function,
        };
      }).reduce((acc, val) => Object.assign(acc, val), {})
    ),
    currentRule() {
      return FORECAST_RULES.find((rule) => rule.enum === this.editObject.enum);
    },
    idField() {
      return this.currentRule?.get?.key;
    },
    valueField() {
      return this.currentRule?.get?.value;
    },
    currentValues() {
      const key = this.editObject?.enum;
      if (!key) return [];
      const object = this[key];
      if (typeof this.currentRule.get.index === "undefined") {
        return object;
      }
      return object(this.currentRule.get.index);
    },
    currentValueNames() {
      return this.selectedItems.map((it) => {
        return this.currentValues.find((cv) => cv[this.idField] === it)[
          this.valueField
        ];
      });
    },
    arraySummary() {
      return generateArraySummaryTranslated.call(this, this.currentValueNames);
    },
  },
  methods: {
    getData() {
      return this.selectedItems;
    },
  },
};
</script>

<style scoped></style>
