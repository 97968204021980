<template>
  <div
    class="workers mx-0 container"
    v-if="
      projectDetails.employeeWorkDetails &&
      projectDetails.employeeWorkDetails.length
    "
  >
    <ProjectCollapse id="worker-list">
      <template #header>
        <h3 class="title">
          {{
            $tf(
              "projectPage.workersList.title|Dolgozói és kompetencia lebontás"
            )
          }}
          <b-tooltip
            type="is-info"
            :triggers="['click']"
            :auto-close="['outside', 'escape']"
          >
            <template v-slot:content>
              <div class="content tooltip">
                <p>
                  {{
                    $tf(
                      "projectPage.workersList.info|A projektre logolt idők és költségek megoszlása a résztvevők alapján. A megjelenített ráta átlag, ha a dolgozó besorolása esetleg a projekt közben változott. A projektre vonatkozó esetleges költségfelülírás az itteni számításra is érvényes. A költség kiszámítása itt is percre pontos kerekítéssel van kiszámolva."
                    )
                  }}
                </p>
              </div>
            </template>
            <portal-icon icon="info-circle" />
          </b-tooltip>
        </h3>
      </template>
      <div class="content">
        <b-tabs v-model="workerTab" type="is-toggle" class="toggle-only">
          <b-tab-item
            key="projectWorker"
            :label="
              $tf('projectPage.workersList.tabs.wholeProject|Teljes projekt')
            "
            id="projectWorker"
            :visible="true"
            icon="folder"
          >
          </b-tab-item>
          <b-tab-item
            v-for="tigDetails in sortedTigs"
            :key="tigDetails.key"
            :label="tigDetails.name"
            :id="'worker' + tigDetails.key"
            :visible="
              tigDetails.status === 'ONGOING' ||
              tigDetails.status === 'PENDING' ||
              tigDetails.status === 'COMPLETED'
            "
            icon="file"
          >
          </b-tab-item>
        </b-tabs>

        <div>
          <h2 class="title has-text-dark-blue">
            {{ $tf("projectPage.workersList.byWorkers|Dolgozók szerint") }}
          </h2>
          <div class="employee-graph">
            <template v-if="noDataForEmployees">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon
                      icon="exclamation-triangle"
                      size="is-large"
                    ></b-icon>
                  </p>
                  <p>
                    {{
                      $tf(
                        "projectPage.workersList.byWorkers.atLeastOneWorkerNeeded|Legalább egy dolgozót ki kell jelölni!"
                      )
                    }}
                  </p>
                </div>
              </section>
            </template>
            <template v-else>
              <div class="columns">
                <div class="column is-half">
                  <h3 class="heading">
                    {{
                      $tf(
                        "projectPage.workersList.byWorkers.byTimelogs|Logolt idő szerint"
                      )
                    }}
                  </h3>
                  <apex-chart
                    type="bar"
                    height="300"
                    :options="monthlyBarChartOptionsByEmployeeTime"
                    :series="monthlyBarChartDataByEmployees"
                  ></apex-chart>
                </div>
                <div class="column is-half">
                  <h3 class="heading">
                    {{
                      $tf(
                        "projectPage.workersList.byWorkers.byCost|Költség szerint"
                      )
                    }}
                  </h3>
                  <apex-chart
                    type="bar"
                    height="300"
                    :options="monthlyBarChartOptionsByEmployeeMoney"
                    :series="monthlyBarChartDataByEmployeesMoney"
                  ></apex-chart>
                </div>
              </div>
            </template>
          </div>

          <h3 class="heading">
            {{
              $tf(
                "projectPage.workersList.byWorkers.selectData.title|Adatok kiválasztása"
              )
            }}
          </h3>
          <h2 class="subtitle">
            {{
              $tf(
                "projectPage.workersList.byWorkers.selectData.subitle|A kijelölt dolgozók adatai jelennek meg a grafikonokon, illetve számítódnak bele a táblázat összegző sorába."
              )
            }}
          </h2>

          <div>
            <div class="is-flex mb-1 mt-4">
              <b-button
                type="is-info"
                size="is-small"
                class="mr-1"
                rounded
                @click="doSelectAllEmployees"
                >{{
                  $tf(
                    "projectPage.workersList.byWorkers.selectData.all|Összes kijelölése"
                  )
                }}
              </b-button>
              <b-button
                type="is-info"
                size="is-small"
                rounded
                @click="unSelectAllEmployees"
                :disabled="noEmployeesSelected"
                >{{
                  $tf(
                    "projectPage.workersList.byWorkers.selectData.none|Kijelöltek törlése"
                  )
                }}
              </b-button>
            </div>
            <div class="overflow-x-auto">
              <table class="worker-table">
                <tr>
                  <th colspan="4"></th>
                  <th
                    class="bg-lightgray"
                    style="
                      border-top-left-radius: 8px;
                      border-bottom-left-radius: 8px;
                    "
                  >
                    {{
                      $tf(
                        "projectPage.workersList.byWorkers.table.sum|Összesen"
                      )
                    }}
                  </th>
                  <th
                    class="bg-lightgray"
                    v-for="(month, index) in months"
                    :key="month"
                    :style="{
                      borderRadius:
                        index === months.length - 1 ? '0 8px 8px 0' : '0',
                    }"
                  >
                    {{ month }}
                  </th>
                </tr>
                <tr class="blank-row-small">
                  <td></td>
                </tr>
                <template v-for="emp in employees" :key="emp.externalId">
                  <tr
                    :class="{
                      'inactive-row': !selectedEmployees[
                        selectionIndex
                      ].includes(emp.externalId),
                    }"
                  >
                    <td rowspan="2" style="width: 45px" class="checkbox-cell">
                      <div
                        class="is-flex is-justify-content-space-between is-align-items-center"
                        style="width: 45px"
                      >
                        <div>
                          <b-checkbox
                            v-model="selectedEmployees[selectionIndex]"
                            :native-value="emp.externalId"
                          />
                        </div>
                        <div
                          style="width: 5px; height: 64px; border-radius: 50px"
                          :style="{ backgroundColor: emp.color }"
                        />
                      </div>
                    </td>
                    <td rowspan="2" style="width: 64px">
                      <p class="image is-64x64">
                        <img
                          class="is-rounded"
                          :src="
                            emp.avatar
                              ? emp.avatar
                              : 'https://cdn2.iconfinder.com/data/icons/social-flat-buttons-3/512/anonymous-512.png'
                          "
                          alt="Avatar"
                        />
                      </p>
                    </td>
                    <td class="has-dotted-bottom-border">
                      <span class="has-font-weight-500">{{ emp.name }}</span>
                    </td>
                    <td class="has-dotted-bottom-border">
                      {{
                        $tf(
                          "projectPage.workersList.byWorkers.table.timeSpent|Logolt idő"
                        )
                      }}
                    </td>
                    <td class="has-dotted-bottom-border">
                      <span class="has-font-weight-500">{{
                        secondToShortWorkDayFormat(emp.timespent)
                      }}</span>
                    </td>
                    <td
                      v-for="(monthlyLog, index) in emp.monthlyLogged"
                      :key="index"
                      class="has-dotted-bottom-border"
                    >
                      {{ secondToShortWorkDayFormat(monthlyLog) }}
                    </td>
                  </tr>
                  <tr
                    :class="{
                      'inactive-row': !selectedEmployees[
                        selectionIndex
                      ].includes(emp.externalId),
                    }"
                  >
                    <td>{{ moneyify(emp.avgRate) }}/h</td>
                    <td>
                      {{
                        $tf(
                          "projectPage.workersList.byWorkers.table.moneySpent|Költés"
                        )
                      }}
                    </td>
                    <td>
                      <span class="has-font-weight-500">{{
                        moneyify(emp.moneyspent)
                      }}</span>
                    </td>
                    <td
                      v-for="(monthlySpent, index) in emp.monthlySpent"
                      :key="index"
                    >
                      {{ moneyify(monthlySpent) }}
                    </td>
                  </tr>
                  <tr class="blank-row-small">
                    <td></td>
                  </tr>
                </template>

                <tr>
                  <td colspan="2" rowspan="2"></td>
                  <td
                    rowspan="2"
                    class="bg-black"
                    style="border-radius: 8px 0 0 8px"
                  >
                    <div
                      class="is-flex is-align-items-center has-font-weight-500"
                      style="height: 64px"
                    >
                      {{
                        $tf(
                          "projectPage.workersList.byWorkers.table.sum|Összesen"
                        )
                      }}
                    </div>
                  </td>
                  <td class="bg-black has-dotted-bottom-border">
                    {{
                      $tf(
                        "projectPage.workersList.byWorkers.table.timeSpent|Logolt idő"
                      )
                    }}
                  </td>
                  <td
                    class="bg-black has-font-weight-500 has-dotted-bottom-border"
                  >
                    {{
                      secondToShortWorkDayFormat(
                        monthlySelectedEmployeeData.reduce(
                          (partialSum, a) => partialSum + a.timespent,
                          0
                        )
                      )
                    }}
                  </td>
                  <td
                    v-for="(m, index) in months"
                    :key="index"
                    class="bg-black has-dotted-bottom-border"
                    :style="{
                      borderTopRightRadius:
                        index === months.length - 1 ? '8px' : '0',
                    }"
                  >
                    {{
                      secondToShortWorkDayFormat(
                        monthlySelectedEmployeeData
                          .map((med) => {
                            return med.monthlyLogged[index];
                          })
                          .reduce((partialSum, a) => partialSum + a, 0)
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="bg-black">
                    {{
                      $tf(
                        "projectPage.workersList.byWorkers.table.moneySpent|Költés"
                      )
                    }}
                  </td>
                  <td class="bg-black has-font-weight-500">
                    {{
                      moneyify(
                        monthlySelectedEmployeeData.reduce(
                          (partialSum, a) => partialSum + a.moneyspent,
                          0
                        )
                      )
                    }}
                  </td>
                  <td
                    v-for="(m, index) in months"
                    :key="index"
                    class="bg-black"
                    :style="{
                      borderBottomRightRadius:
                        index === months.length - 1 ? '8px' : '0',
                    }"
                  >
                    {{
                      moneyify(
                        monthlySelectedEmployeeData
                          .map((med) => {
                            return med.monthlySpent[index];
                          })
                          .reduce((partialSum, a) => partialSum + a, 0)
                      )
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="mt-5">
            <h2 class="title has-text-dark-blue">
              {{
                $tf(
                  "projectPage.workersList.byCompetencies|Kompetenciák szerint"
                )
              }}
            </h2>
            <div class="employee-graph">
              <template v-if="noDataForCompetencies">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon
                        icon="exclamation-triangle"
                        size="is-large"
                      ></b-icon>
                    </p>
                    <p>
                      {{
                        $tf(
                          "projectPage.workersList.byCompetencies.atLeastOneCompetencyNeeded|Legalább egy kompetenciát ki kell jelölni!"
                        )
                      }}
                    </p>
                  </div>
                </section>
              </template>
              <template v-else>
                <div class="columns">
                  <div class="column is-half">
                    <h3 class="heading">
                      {{
                        $tf(
                          "projectPage.workersList.byCompetencies.byTimelogs|Logolt idő szerint"
                        )
                      }}
                    </h3>
                    <apex-chart
                      type="bar"
                      height="300"
                      :options="monthlyBarChartOptionsByCompetencyTime"
                      :series="monthlyBarChartDataByCompetencies"
                    ></apex-chart>
                  </div>
                  <div class="column is-half">
                    <h3 class="heading">
                      {{
                        $tf(
                          "projectPage.workersList.byCompetencies.byCost|Költség szerint"
                        )
                      }}
                    </h3>
                    <apex-chart
                      type="bar"
                      height="300"
                      :options="monthlyBarChartOptionsByCompetencyMoney"
                      :series="monthlyBarChartDataByCompetenciesMoney"
                    ></apex-chart>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <h3 class="heading">
            {{
              $tf(
                "projectPage.workersList.byCompetencies.selectData.title|Adatok kiválasztása"
              )
            }}
          </h3>
          <h2 class="subtitle">
            {{
              $tf(
                "projectPage.workersList.byCompetencies.subtitle|A kijelölt kompetenciák adatai jelennek meg a grafikonokon, illetve számítódnak bele a táblázat összegző sorába. Egy dolgozó csak egy kompetenciába számít. Ha több kompetenciával rendelkezik, a kijelölt kompetenciák közül a projekten belüli legnagyobb időtartamúba lesz számítva."
              )
            }}
          </h2>

          <div>
            <div class="is-flex mb-1">
              <b-button
                type="is-info"
                size="is-small"
                class="mr-1"
                rounded
                @click="doSelectAllCompetencies"
                >{{
                  $tf(
                    "projectPage.workersList.byCompetencies.selectData.all|Összes kijelölése"
                  )
                }}
              </b-button>
              <b-button
                type="is-info"
                size="is-small"
                rounded
                @click="unSelectAllCompetencies"
                :disabled="noCompetenciesSelected"
                >{{
                  $tf(
                    "projectPage.workersList.byCompetencies.selectData.none|Kijelöltek törlése"
                  )
                }}
              </b-button>
            </div>

            <div class="overflow-x-auto">
              <table class="worker-table">
                <tr>
                  <th colspan="3"></th>
                  <th
                    class="bg-lightgray"
                    style="
                      border-top-left-radius: 8px;
                      border-bottom-left-radius: 8px;
                    "
                  >
                    {{
                      $tf(
                        "projectPage.workersList.byCompetencies.table.sum|Összesen"
                      )
                    }}
                  </th>
                  <th
                    class="bg-lightgray"
                    v-for="(month, index) in months"
                    :key="month"
                    :style="{
                      borderRadius:
                        index === months.length - 1 ? '0 8px 8px 0' : '0',
                    }"
                  >
                    {{ month }}
                  </th>
                </tr>
                <tr class="blank-row-small">
                  <td></td>
                </tr>
                <template v-for="comp in filteredCompetencies" :key="comp.id">
                  <tr
                    :class="{
                      'inactive-row': !selectedCompetencies[
                        selectionIndex
                      ].includes(comp.id),
                    }"
                  >
                    <td rowspan="2" style="width: 45px" class="checkbox-cell">
                      <div
                        class="is-flex is-justify-content-space-between is-align-items-center"
                        style="width: 45px"
                      >
                        <div>
                          <b-checkbox
                            v-model="selectedCompetencies[selectionIndex]"
                            :native-value="comp.id"
                          />
                        </div>
                        <div
                          style="width: 5px; height: 64px; border-radius: 50px"
                          :style="{ backgroundColor: comp.color }"
                        />
                      </div>
                    </td>
                    <td rowspan="2" style="vertical-align: middle">
                      <div
                        class="has-font-weight-500 is-flex is-align-items-center has-gap-2"
                      >
                        <span>{{ comp.name }}</span>
                        <span>
                          <b-tooltip v-if="comp.skipWarning" type="is-warning">
                            <template v-slot:content>
                              <div class="content tooltip">
                                <p class="has-text-centered">
                                  {{
                                    $tf(
                                      "projectPage.workersList.byCompetencies.table.skipWarning|Ebbe a kompetenciába nem került számolásra az összes, ezzel a kompetenciával rendelkező dolgozó, mert van olyan, aki több kompetenciával is rendelkezik!"
                                    )
                                  }}
                                </p>
                              </div>
                            </template>
                            <b-icon
                              icon="triangle-exclamation"
                              type="is-warning"
                              size="is-small"
                            /> </b-tooltip
                        ></span>
                      </div>
                    </td>
                    <td class="has-dotted-bottom-border">
                      {{
                        $tf(
                          "projectPage.workersList.byCompetencies.table.timeSpent|Logolt idő"
                        )
                      }}
                    </td>
                    <td class="has-dotted-bottom-border">
                      <span class="has-font-weight-500">{{
                        secondToShortWorkDayFormat(comp.filteredTimespent)
                      }}</span>
                    </td>
                    <td
                      v-for="(monthlyLog, index) in comp.monthlyLogged"
                      :key="index"
                      class="has-dotted-bottom-border"
                    >
                      {{ secondToShortWorkDayFormat(monthlyLog) }}
                    </td>
                  </tr>
                  <tr
                    :class="{
                      'inactive-row': !selectedCompetencies[
                        selectionIndex
                      ].includes(comp.id),
                    }"
                  >
                    <td>
                      {{
                        $tf(
                          "projectPage.workersList.byCompetencies.table.moneySpent|Költés"
                        )
                      }}
                    </td>
                    <td>
                      <span class="has-font-weight-500">{{
                        moneyify(comp.filteredMoneyspent)
                      }}</span>
                    </td>
                    <td
                      v-for="(monthlySpent, index) in comp.monthlySpent"
                      :key="index"
                    >
                      {{ moneyify(monthlySpent) }}
                    </td>
                  </tr>
                  <tr class="blank-row-small">
                    <td></td>
                  </tr>
                </template>

                <tr>
                  <td rowspan="2"></td>
                  <td
                    rowspan="2"
                    class="bg-black"
                    style="border-radius: 8px 0 0 8px"
                  >
                    <div
                      class="is-flex is-align-items-center has-font-weight-500"
                      style="height: 64px"
                    >
                      {{
                        $tf(
                          "projectPage.workersList.byCompetencies.table.sum|Összesen"
                        )
                      }}
                    </div>
                  </td>
                  <td class="bg-black has-dotted-bottom-border">
                    {{
                      $tf(
                        "projectPage.workersList.byCompetencies.table.timeSpent|Logolt idő"
                      )
                    }}
                  </td>
                  <td
                    class="bg-black has-font-weight-500 has-dotted-bottom-border"
                  >
                    {{
                      secondToShortWorkDayFormat(
                        filteredCompetencies
                          .filter((fc) =>
                            selectedCompetencies[selectionIndex].includes(fc.id)
                          )
                          .reduce(
                            (partialSum, a) => partialSum + a.filteredTimespent,
                            0
                          )
                      )
                    }}
                  </td>
                  <td
                    v-for="(m, index) in months"
                    :key="index"
                    class="bg-black has-dotted-bottom-border"
                    :style="{
                      borderTopRightRadius:
                        index === months.length - 1 ? '8px' : '0',
                    }"
                  >
                    {{
                      secondToShortWorkDayFormat(
                        filteredCompetencies
                          .filter((fc) =>
                            selectedCompetencies[selectionIndex].includes(fc.id)
                          )
                          .map((med) => {
                            return med.monthlyLogged[index];
                          })
                          .reduce((partialSum, a) => partialSum + a, 0)
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="bg-black">
                    {{
                      $tf(
                        "projectPage.workersList.byCompetencies.table.moneySpent|Költés"
                      )
                    }}
                  </td>
                  <td class="bg-black has-font-weight-500">
                    {{
                      moneyify(
                        filteredCompetencies
                          .filter((fc) =>
                            selectedCompetencies[selectionIndex].includes(fc.id)
                          )
                          .reduce(
                            (partialSum, a) =>
                              partialSum + a.filteredMoneyspent,
                            0
                          )
                      )
                    }}
                  </td>
                  <td
                    v-for="(m, index) in months"
                    :key="index"
                    class="bg-black"
                    :style="{
                      borderBottomRightRadius:
                        index === months.length - 1 ? '8px' : '0',
                    }"
                  >
                    {{
                      moneyify(
                        filteredCompetencies
                          .filter((fc) =>
                            selectedCompetencies[selectionIndex].includes(fc.id)
                          )
                          .map((med) => {
                            return med.monthlySpent[index];
                          })
                          .reduce((partialSum, a) => partialSum + a, 0)
                      )
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="mt-5 is-flex is-justify-content-space-between">
            <div>
              <h3 class="heading">
                {{
                  $tf(
                    "projectPage.workersList.byCompetencies.distribution.title|Kompetencia megoszlás"
                  )
                }}
              </h3>
              <h2 class="subtitle">
                {{
                  $tf(
                    "projectPage.workersList.byCompetencies.distribution.subtitle|Kompetenciák megoszlása összesítve, a teljes projekt, vagy a kiválasztott mérföldkő ideje alatt."
                  )
                }}
              </h2>
              <div
                class="is-flex is-flex-direction-column has-gap-2 has-text-dark-blue"
              >
                <div
                  v-for="comp in filteredCompetencies"
                  :key="comp.id"
                  class="is-flex has-gap-2 is-align-items-center"
                >
                  <div
                    style="width: 16px; height: 16px; border-radius: 4px"
                    :style="{ background: comp.color }"
                  ></div>
                  <div>{{ comp.name }}</div>
                </div>
              </div>
            </div>
            <div class="is-flex is-flex-direction-column is-align-items-center">
              <h3 class="heading">
                {{
                  $tf(
                    "projectPage.workersList.byCompetencies.distribution.byTimeSpent|Logolt idő szerint"
                  )
                }}
              </h3>
              <apex-chart
                :options="competenciesRadialOptions"
                :series="competenciesRadialTimeData"
                :height="200"
              />
            </div>
            <div class="is-flex is-flex-direction-column is-align-items-center">
              <h3 class="heading">
                {{
                  $tf(
                    "projectPage.workersList.byCompetencies.distribution.byMoneySpent|Költés szerint"
                  )
                }}
              </h3>
              <apex-chart
                :options="competenciesRadialOptions"
                :series="competenciesRadialMoneyData"
                :height="200"
              />
            </div>
          </div>
        </div>
      </div>
    </ProjectCollapse>
  </div>
</template>

<script>
import {
  secondToWorkDayFormat,
  secondToShortWorkDayFormat,
  moneyify,
  deepCopy,
  workdayify,
} from "@/utils/util";
import PortalIcon from "@/components/module/icon/PortalIcon";
import ProjectCollapse from "@/components/enterprise/project/module/ProjectCollapse";
import { mapGetters, mapState } from "vuex";
import dayjs from "dayjs";
import { CURRENCY_TIERS } from "@/utils/const";

export default {
  name: "WorkersList",
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    outerTigDetails: {
      type: Object,
      required: false,
    },
  },
  components: {
    ProjectCollapse,
    PortalIcon,
  },
  data() {
    return {
      workerTab: 0,
      moneyify,
      workdayify,
      secondToWorkDayFormat,
      secondToShortWorkDayFormat,
      selectedEmployees: [],
      selectedCompetencies: [],
      graphType: 0,
      showSaturday: false,
      showSunday: false,
    };
  },
  created() {
    this.selectedEmployees[0] = this.projectDetails.employeeWorkDetails
      ? this.projectDetails.employeeWorkDetails.map(
          (employee) => employee.externalId
        )
      : [];
    this.selectedCompetencies[0] = this.competencies.map((comp) => comp.id);
    for (let t in this.projectDetails.tigDetails) {
      let key = this.projectDetails.tigDetails[t].key;
      let index = parseInt(/[^-]*$/.exec(key)[0]);
      this.selectedEmployees[index] = this.projectDetails.tigDetails[t]
        .employeeWorkDetails
        ? this.projectDetails.tigDetails[t].employeeWorkDetails.map(
            (employee) => employee.externalId
          )
        : [];
      this.selectedCompetencies[index] = this.competencies.map(
        (comp) => comp.id
      );
    }
  },
  computed: {
    sourceData() {
      return this.workerTab === 0 ? this.projectDetails : this.selectedTig;
    },
    donutTimeOptions() {
      let labels = [];
      if (this.noCompetenciesSelected) {
        let employees = this.selectedEmployeeObjects;
        for (let d in employees) {
          let emp = employees[d];
          labels.push(
            emp.name + ": " + this.secondToWorkDayFormat(emp.timespent)
          );
        }
      } else {
        let time = {};
        for (let competency of this.selectedCompetencies[this.selectionIndex]) {
          let competencyName = this.selectedCompetencyEmployees
            .find((employee) =>
              employee.competencies.some((c) => c.id === competency)
            )
            .competencies.find((c) => c.id === competency).name;
          for (let employee of this.selectedCompetencyEmployees.filter(
            (employee) =>
              employee.competencies?.some((c) => c.id === competency)
          )) {
            if (time[competency]) {
              time[competency].time += employee.timespent;
            } else {
              time[competency] = {
                name: competencyName,
                time: employee.timespent,
              };
            }
          }
        }
        labels.push(...Object.values(time));
        labels.forEach(
          (value, index) =>
            (labels[index] =
              value.name + ": " + secondToWorkDayFormat(value.time))
        );
      }

      return {
        chart: {
          type: "donut",
        },
        labels: labels,
        plotOptions: {
          chart: {
            width: 200,
          },
        },
        legend: {
          show: false,
        },
        colors: this.selectedColors,
      };
    },
    donutTimeData() {
      let distro = [];
      if (this.noCompetenciesSelected) {
        let employees = this.selectedEmployeeObjects;
        for (let d in employees) {
          let emp = employees[d];
          distro.push(emp.timespent);
        }
      } else {
        let time = {};
        for (let competency of this.selectedCompetencies[this.selectionIndex]) {
          for (let employee of this.selectedCompetencyEmployees.filter(
            (employee) =>
              employee.competencies?.some((c) => c.id === competency)
          )) {
            if (time[competency]) {
              time[competency] += employee.timespent;
            } else {
              time[competency] = employee.timespent;
            }
          }
        }
        distro.push(...Object.values(time));
      }
      return distro;
    },
    donutMoneyOptions() {
      let labels = [];
      if (this.noCompetenciesSelected) {
        let employees = this.selectedEmployeeObjects;
        for (let d in employees) {
          let emp = employees[d];
          labels.push(emp.name + ": " + moneyify(emp.moneyspent));
        }
      } else {
        let money = {};
        for (let competency of this.selectedCompetencies[this.selectionIndex]) {
          let competencyName = this.selectedCompetencyEmployees
            .find((employee) =>
              employee.competencies.some((c) => c.id === competency)
            )
            .competencies.find((c) => c.id === competency).name;
          for (let employee of this.selectedCompetencyEmployees.filter(
            (employee) =>
              employee.competencies?.some((c) => c.id === competency)
          )) {
            if (money[competency]) {
              money[competency].money += employee.moneyspent;
            } else {
              money[competency] = {
                name: competencyName,
                money: employee.moneyspent,
              };
            }
          }
        }
        labels.push(...Object.values(money));
        labels.forEach(
          (value, index) =>
            (labels[index] = value.name + ": " + moneyify(value.money))
        );
      }

      return {
        chart: {
          type: "donut",
        },
        labels: labels,
        plotOptions: {
          chart: {
            width: 200,
          },
        },
        legend: {
          show: false,
        },
        colors: this.selectedColors,
      };
    },
    donutMoneyData() {
      let distro = [];
      if (this.noCompetenciesSelected) {
        let employees = this.selectedEmployeeObjects;
        for (let d in employees) {
          let emp = employees[d];
          distro.push(emp.moneyspent);
        }
      } else {
        let money = {};
        for (let competency of this.selectedCompetencies[this.selectionIndex]) {
          for (let employee of this.selectedCompetencyEmployees.filter(
            (employee) =>
              employee.competencies?.some((c) => c.id === competency)
          )) {
            if (money[competency]) {
              money[competency] += employee.moneyspent;
            } else {
              money[competency] = employee.moneyspent;
            }
          }
        }
        distro.push(...Object.values(money));
      }
      return distro;
    },
    barChartOptions() {
      const self = this;
      return {
        chart: {
          type: "line",
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          type: "datetime",
        },
        tooltip: {
          y: {
            formatter: function (value, { seriesIndex, dataPointIndex }) {
              return `<div style="margin-left: 10px;"">${
                Math.floor(value / 8) > 0 ? Math.floor(value / 8) + "d " : ""
              }${
                value % 8 > 0
                  ? (value % 8) + "h"
                  : Math.floor(value / 8) > 0
                  ? ""
                  : "-"
              }<br>${
                self.barChartData[seriesIndex].data[dataPointIndex]?.log
              }</div>`;
            },
            title: {
              formatter: (seriesName) => `<div>${seriesName}</div>`,
            },
          },
        },
        colors: this.selectedColors,
      };
    },
    endDate() {
      let end = new Date(
        this.sourceData?.endDate
          ? this.sourceData.endDate
          : this.sourceData?.dueDate
          ? this.sourceData.dueDate
          : null
      );
      if (end > new Date()) end = new Date();
      return end;
    },
    monthNumber() {
      return (
        Math.ceil(
          dayjs(this.endDate).diff(dayjs(this.startDate), "month", true)
        ) + 1
      );
    },
    months() {
      let months = [];

      let actYear = dayjs(this.startDate).year();
      let actMonth = dayjs(this.startDate).month();

      for (let i = 0; i < this.monthNumber; i++) {
        months.push(dayjs(new Date(actYear, actMonth, 1)).format("YYYY. MM."));
        actMonth++;
        if (actMonth > 12) {
          actMonth = 1;
          actYear++;
        }
      }

      return months;
    },
    monthlySelectedEmployeeData() {
      return this.monthlyEmployeeData.filter((med) =>
        this.selectedEmployeeObjects
          .map((seo) => seo.externalId)
          .includes(med.externalId)
      );
    },
    monthlyEmployeeData() {
      let distro = [];

      for (let employee of this.employees) {
        let issues = this.worklogs?.filter(
          (worklog) => worklog?.authorId === employee.externalId
        );

        let monthlyLogged = [];
        let monthlySpent = [];

        let actYear = dayjs(this.startDate).year();
        let actMonth = dayjs(this.startDate).month();

        for (let i = 0; i < this.monthNumber; i++) {
          let actualMonthIssues = issues.filter(
            (iss) =>
              dayjs(iss.day).year() === actYear &&
              dayjs(iss.day).month() === actMonth
          );

          monthlyLogged.push(
            actualMonthIssues.reduce(
              (partialSum, a) => partialSum + a.seconds,
              0
            )
          );
          monthlySpent.push(
            actualMonthIssues.reduce(
              (partialSum, a) =>
                partialSum + (a.seconds / 3600) * employee.avgRate,
              0
            )
          );

          actMonth++;
          if (actMonth > 11) {
            actMonth = 0;
            actYear++;
          }
        }

        distro.push(
          Object.assign(employee, {
            monthlyLogged: monthlyLogged,
            monthlySpent: monthlySpent,
          })
        );
      }
      return distro;
    },
    monthlyBarChartOptionsByEmployeeTime() {
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            borderRadiusApplication: "end",
            borderRadiusWhenStacked: "last",
            dataLabels: {
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
          show: false,
        },
        xaxis: {
          categories: this.months,
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            formatter: function (val) {
              return workdayify(val / 28800);
            },
            style: {
              colors: "var(--text)",
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return secondToShortWorkDayFormat(val);
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        colors: this.selectedEmployeeColors,
      };
    },
    monthlyBarChartOptionsByCompetencyTime() {
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            borderRadiusApplication: "end",
            borderRadiusWhenStacked: "last",
            dataLabels: {
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
          show: false,
        },
        xaxis: {
          categories: this.months,
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            formatter: function (val) {
              return workdayify(val / 28800);
            },
            style: {
              colors: ["var(--text)"],
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return secondToShortWorkDayFormat(val);
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        colors: this.selectedCompetencyColors,
      };
    },
    monthlyBarChartDataByEmployees() {
      return this.monthlySelectedEmployeeData.map((md) => {
        return { name: md.name, data: md.monthlyLogged };
      });
    },
    monthlyBarChartOptionsByEmployeeMoney() {
      const self = this;
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            borderRadiusApplication: "end",
            borderRadiusWhenStacked: "last",
            dataLabels: {
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
          show: false,
        },
        xaxis: {
          categories: this.months,
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            formatter: function (val) {
              return moneyify.call(self, CURRENCY_TIERS.LEVEL_ONE);
            },
            style: {
              colors: "var(--text)",
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return moneyify.call(self, Math.round(val));
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        colors: this.selectedEmployeeColors,
      };
    },
    monthlyBarChartOptionsByCompetencyMoney() {
      const self = this;
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            borderRadiusApplication: "end",
            borderRadiusWhenStacked: "last",
            dataLabels: {
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
          show: false,
        },
        xaxis: {
          categories: this.months,
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            formatter: function (val) {
              return moneyify.call(self, val, CURRENCY_TIERS.LEVEL_ONE);
            },
            style: {
              colors: "var(--text)",
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return moneyify.call(self, Math.round(val));
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        colors: this.selectedCompetencyColors,
      };
    },
    monthlyBarChartDataByEmployeesMoney() {
      return this.monthlySelectedEmployeeData.map((md) => {
        return { name: md.name, data: md.monthlySpent };
      });
    },
    monthlyBarChartDataByCompetencies() {
      return this.filteredCompetencies
        .filter((fc) =>
          this.selectedCompetencies[this.selectionIndex].includes(fc.id)
        )
        .map((fc) => {
          return { name: fc.name, data: fc.monthlyLogged };
        });
    },
    monthlyBarChartDataByCompetenciesMoney() {
      return this.filteredCompetencies
        .filter((fc) =>
          this.selectedCompetencies[this.selectionIndex].includes(fc.id)
        )
        .map((fc) => {
          return { name: fc.name, data: fc.monthlySpent };
        });
    },
    sortedTigs() {
      let sorted = deepCopy(this.projectDetails.tigDetails);
      if (!sorted) return [];
      return sorted.sort((a, b) =>
        a.key > b.key ? 1 : b.key > a.key ? -1 : 0
      );
    },
    selectedTig() {
      return this.sortedTigs[this.workerTab - 1];
    },
    selectedColors() {
      return this.noCompetenciesSelected
        ? this.selectedEmployeeColors
        : this.selectedCompetencyColors;
    },
    selectedEmployeeColors() {
      return this.selectedEmployeeObjects.map((emp) => emp.color);
    },
    selectedCompetencyColors() {
      return this.filteredCompetencies
        .filter((c) =>
          this.selectedCompetencies[this.selectionIndex].some(
            (cc) => c.id === cc
          )
        )
        .map((comp) => comp.color);
    },
    selectedEmployeeObjects() {
      let array = this.employees;
      return array?.filter((employee) =>
        this.selectedEmployees[this.selectionIndex]?.includes(
          employee.externalId
        )
      );
    },
    selectedCompetencyEmployees() {
      let array = this.employees;
      if (!array) return [];
      array = array?.filter((employee) => {
        let worker = this.workers.find(
          (w) => w.externalId === employee.externalId
        );
        if (!worker) return false;
        for (let competency of worker.competencies) {
          if (
            this.selectedCompetencies[this.selectionIndex]?.includes(
              competency.id
            )
          )
            return true;
        }
      });
      for (let employee of array) {
        let worker = this.workers.find(
          (w) => w.externalId === employee.externalId
        );
        for (let [key, value] of Object.entries(worker)) {
          employee[key] = value;
        }
      }
      return array;
    },
    employees() {
      let toReturn =
        !this.sourceData.employeeWorkDetails ||
        !this.sourceData.employeeWorkDetails.length
          ? []
          : this.sourceData.employeeWorkDetails;
      let count = 2;
      toReturn.forEach((emp) => {
        const hue = count++ * 137.508; // use golden angle approximation
        emp.color = `hsl(${hue}, 75%, ${Math.random() * 40 + 31}%)`;
      });
      return toReturn;
    },
    selectionIndex() {
      return this.workerTab === 0
        ? 0
        : parseInt(/[^-]*$/.exec(this.sourceData.key)[0]);
    },
    noEmployeesSelected() {
      return (
        !this.selectedEmployees[this.selectionIndex] ||
        this.selectedEmployees[this.selectionIndex].length === 0
      );
    },
    noCompetenciesSelected() {
      return (
        !this.selectedCompetencies[this.selectionIndex] ||
        this.selectedCompetencies[this.selectionIndex].length === 0
      );
    },
    noDataForEmployees() {
      return this.noEmployeesSelected;
    },
    noDataForCompetencies() {
      return this.noCompetenciesSelected;
    },
    competencies() {
      let competencies = [];
      let count = 44;
      for (let employee of this.employees) {
        let worker = this.workers.find(
          (w) => w.externalId === employee.externalId
        );
        if (!worker) continue;
        for (let competency of worker.competencies) {
          let prev = competencies.find((c) => c.id === competency.id);
          if (!!prev) {
            prev.timespent += employee.timespent;
            prev.moneyspent += employee.moneyspent;
            prev.employees.push(employee.externalId);
          } else {
            competencies.push({
              id: competency.id,
              name: competency.name,
              timespent: employee.timespent,
              moneyspent: employee.moneyspent,
              color: `hsl(${count++ * 137.508}, 75%, ${
                Math.random() * 40 + 31
              }%)`,
              employees: [employee.externalId],
            });
          }
        }
      }
      return competencies.sort((a, b) =>
        a.id > b.id ? 1 : b.id > a.id ? -1 : 0
      );
    },
    filteredCompetencies() {
      let filteredCompetencies = [];

      for (let i = 0; i < this.monthlyEmployeeData.length; i++) {
        let emp = this.monthlyEmployeeData[i];

        let employeeCompetencies = this.competencies.filter((c) =>
          c.employees.includes(emp.externalId)
        );

        let maxSpentCompetency = null;

        if (employeeCompetencies.length === 0) {
          continue;
        } else if (employeeCompetencies.length === 1) {
          maxSpentCompetency = employeeCompetencies[0];
        } else {
          maxSpentCompetency = employeeCompetencies.reduce((prev, current) =>
            prev.timespent > current.timespent ? prev : current
          );
        }

        let previousFilteredCompetency = filteredCompetencies.find(
          (fc) => fc.id === maxSpentCompetency.id
        );

        if (previousFilteredCompetency) {
          previousFilteredCompetency.filteredEmployees.push(emp.externalId);
          previousFilteredCompetency.filteredTimespent += emp.timespent;
          previousFilteredCompetency.filteredMoneyspent += emp.moneyspent;
          for (let i = 0; i < this.monthNumber; i++) {
            previousFilteredCompetency.monthlyLogged[i] += emp.monthlyLogged[i];
            previousFilteredCompetency.monthlySpent[i] += emp.monthlySpent[i];
          }
        } else {
          filteredCompetencies.push(
            Object.assign(maxSpentCompetency, {
              filteredTimespent: emp.timespent,
              filteredMoneyspent: emp.moneyspent,
              filteredEmployees: [emp.externalId],
              monthlyLogged: [...emp.monthlyLogged],
              monthlySpent: [...emp.monthlySpent],
              skipWarning: false,
            })
          );
        }

        employeeCompetencies
          .filter((ec) => ec.id !== maxSpentCompetency.id)
          .forEach((compToWarn) => {
            let toWarn = filteredCompetencies.find(
              (fc) => fc.id === compToWarn.id
            );
            if (toWarn) {
              toWarn.skipWarning = true;
            }
          });
      }

      return filteredCompetencies.sort((a, b) =>
        a.id > b.id ? 1 : b.id > a.id ? -1 : 0
      );
    },
    competenciesRadialOptions() {
      return {
        chart: {
          height: 300,
          type: "donut",
        },
        stroke: {
          show: false,
          width: 0,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
            },
            expandOnClick: false,
          },
        },
        legend: {
          show: false,
        },
        colors: this.filteredCompetencies.map((fc) => fc.color),
        labels: this.filteredCompetencies.map((fc) => fc.name),
        tooltip: {
          y: {
            formatter: function (val) {
              return secondToShortWorkDayFormat(val);
            },
          },
        },
      };
    },
    competenciesRadialTimeData() {
      return this.filteredCompetencies.map((fc) => fc.filteredTimespent);
    },
    competenciesRadialMoneyData() {
      return this.filteredCompetencies.map((fc) => fc.filteredMoneyspent);
    },
    worklogs() {
      // fixme: tig based is broken due to identifier
      const base = this.issues(this.projectDetails.id)?.filter(
        (issue) => issue.worklogs?.length > 0
      );
      base?.forEach((issue) =>
        issue.worklogs?.forEach((worklog) => (worklog.key = issue.key))
      );
      return [].concat.apply(
        [],
        base?.map((issue) => issue.worklogs)
      );
    },
    ...mapGetters({
      workers: "employee/workers",
    }),
    ...mapState({
      issues(_state, getters) {
        return getters[
          this.outerTigDetails
            ? "enterprise_core/tigIssues"
            : "enterprise_core/issues"
        ];
      },
    }),
  },
  methods: {
    selectEmployee(externalId) {
      let index =
        this.selectedEmployees[this.selectionIndex].indexOf(externalId);

      const oldArray = this.selectedEmployees[this.selectionIndex];
      if (index !== -1) {
        oldArray.splice(index, 1);
      } else {
        oldArray.push(externalId);
      }
      this.selectedEmployees[this.selectionIndex] = oldArray;
    },
    selectCompetency(id) {
      let index = this.selectedCompetencies[this.selectionIndex]?.indexOf(id);

      if (index === undefined) {
        this.selectedCompetencies[this.selectionIndex] = [];
      }

      const oldArray = this.selectedCompetencies[this.selectionIndex];
      if (index !== -1) {
        oldArray.splice(index, 1);
      } else {
        oldArray.push(id);
      }
      this.selectedCompetencies[this.selectionIndex] = oldArray;
    },
    doSelectAllEmployees() {
      this.selectedEmployees[this.selectionIndex] = this.employees.map(
        (employee) => employee.externalId
      );
    },
    unSelectAllEmployees() {
      this.selectedEmployees[this.selectionIndex] = [];
    },
    doSelectAllCompetencies() {
      this.selectedCompetencies[this.selectionIndex] = this.competencies.map(
        (comp) => comp.id
      );
    },
    unSelectAllCompetencies() {
      this.selectedCompetencies[this.selectionIndex] = [];
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.not-selected {
  opacity: 0.6;
}

.not-selectable {
  opacity: 0.6;
  cursor: not-allowed;

  * {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.circle {
  width: 50px;
  height: 80px;
  margin: -4px;
  border-radius: 0 8px 8px 0;
  box-shadow: 0 5px 30px rgb(48 52 62 / 10%);
}

.checkboxes {
  .is-flex {
    label {
      margin-right: 0;
    }
  }
}

.bg-lightgray {
  background: $grey-lighter;
}

.worker-table th,
.worker-table td:not(.has-dotted-bottom-border) {
  border: none;
  color: $custom-dark-blue;
}

.blank-row-small {
  height: 6px !important;
  line-height: 0;
}

.has-dotted-bottom-border {
  border-bottom: 1px dashed $grey-lighter;
}

.inactive-row > td:not(.checkbox-cell) {
  color: $custom-grayer-blue !important;
  opacity: 0.5;
}

.bg-black {
  background: $black;
  color: $white !important;
}
</style>
