<template>
  <section class="section kontrolling">
    <div class="back ml-5 mb-3 has-font-weight-500">
      <RouterLink to="/projects/list">
        <div class="is-inline-flex is-align-items-center">
          <b-icon icon="chevron-left" />
          <p>{{ $tf("projectPage.backToList|Projektek") }}</p>
        </div>
      </RouterLink>
    </div>

    <div v-if="loading" style="height: 100vh">
      <div class="container">
        <p class="heading">
          {{ $tf("projectPage.loading|Adatok betöltése folyamatban...") }}
        </p>
        <b-skeleton></b-skeleton>
        <b-skeleton width="80%"></b-skeleton>
        <b-skeleton width="40%"></b-skeleton>
        <b-skeleton width="20%"></b-skeleton>
      </div>
    </div>
    <div v-else>
      <BriefData
        :project-data="projectDetails"
        :project-details="projectDetails"
        @edit="bigTab = 1"
      />
      <div class="container">
        <b-tabs
          v-model="bigTab"
          type="is-toggle"
          expanded
          class="tab-without-padding"
        >
          <b-tab-item
            key="current"
            :label="$tf('projectPage.tab.overview|Áttekintés')"
            id="current"
          >
            <support-project-status
              :project-details="projectDetails"
              @updateYear="refreshData(false, $event, null)"
              @updateTypes="refreshData(false, null, $event)"
            />

            <workers-list
              :project-details="projectDetails"
              :start-date="projectDetails.startDate"
            />
          </b-tab-item>
          <b-tab-item
            key="basic"
            :label="$tf('projectPage.tab.basic|Törzsadatok')"
            id="basic"
          >
            <project-edit
              :project-data="projectDetails"
              :project-details="projectDetails"
              project-type="SUPPORT"
              :employees="employees"
            ></project-edit>
            <ProjectCollapse id="journal">
              <template #header>
                <h3 class="title">
                  {{
                    $tf(
                      "projectPage.journal.title|Napló, események, változások"
                    )
                  }}
                  <b-tooltip
                    :auto-close="['outside', 'escape']"
                    :triggers="['click']"
                    type="is-info"
                  >
                    <template v-slot:content>
                      <div class="content tooltip">
                        <p>
                          {{
                            $tf(
                              "projectPage.journal.tooltip|Itt van lehetőség a projekt közben előforduló, a projekt lefolyását befolyásoló események dokumentálására. Futó projekt esetén csak ilyen bejegyzés keretében van lehetőség a projekt bizonyos elemein változtatni, pl.: vállalás, határidő, büdzsé, várható bevétel. A bejegyzések itt, illetve külön dashboardokon önmagukban is láthatóak."
                            )
                          }}
                        </p>
                      </div>
                    </template>
                    <portal-icon icon="info-circle" />
                  </b-tooltip>
                </h3>
              </template>
              <journal-tab-item
                :project-data="projectDetails"
                :project-identifier="projectIdentifier"
                :project-details="projectDetails"
              />
            </ProjectCollapse>
          </b-tab-item>
          <b-tab-item
            key="bugfix"
            :label="$tf('projectPage.tab.fix|Fix díjas')"
            id="bugfix"
          >
            <support-activity-tab-item
              activity-type="FIX"
              activity-field="fixes"
              :project-details="projectDetails"
              :prefix="projectDetails.prefix"
              @refresh="refreshData(true)"
              :employees="employees"
            />
          </b-tab-item>
          <b-tab-item
            key="lekotes"
            :label="$tf('projectPage.tab.lockup|Idő lekötéses')"
            id="lekotes"
          >
            <support-activity-tab-item
              activity-type="LOCKUP"
              activity-field="lockups"
              :project-details="projectDetails"
              :prefix="projectDetails.prefix"
              @refresh="refreshData(true)"
              :employees="employees"
            />
          </b-tab-item>
          <b-tab-item
            key="elszamolhato"
            label="Elszámolható feladatok"
            id="elszamolhato"
          >
            <billable-tab-item
              activity-field="billables"
              :project-details="projectDetails"
              :prefix="projectDetails.prefix"
              @refresh="refreshData(true)"
              :key="refreshKey"
              :employees="employees"
            />
          </b-tab-item>
          <b-tab-item
            key="invoices"
            :label="$tf('projectPage.tab.invoices|Számlák')"
            id="invoices"
          >
            <invoice-tab-item :project-data="projectDetails" :data="[]" />
          </b-tab-item>
        </b-tabs>
      </div>
      <sync-data
        v-if="issueTrackingSystem !== ISSUE_TRACKING_SYSTEM.SELF.enum"
        :project-details="projectDetails"
        :project-identifier="projectIdentifier"
        :project-type="PROJECT_TYPES.SUPPORT"
        @refresh="refreshData(true, selectedYear)"
      />
    </div>
  </section>
</template>

<script>
import InvoiceTabItem from "@/components/enterprise/ProjectDetails/TabItems/InvoiceTabItem.vue";
import SyncData from "@/components/enterprise/ProjectDetails/SyncData.vue";
import JournalTabItem from "@/components/enterprise/ProjectDetails/TabItems/JournalTabItem.vue";
import WorkersList from "@/components/enterprise/ProjectDetails/WorkersList.vue";
import BriefData from "@/components/enterprise/project/module/BriefData.vue";
import ProjectEdit from "@/components/enterprise/project/form/ProjectEdit.vue";
import { mapGetters } from "vuex";
import { moneyify } from "@/utils/util";
import {
  SHORT_MONTH_NAMES,
  PROJECT_TYPES,
  ISSUE_TRACKING_SYSTEM,
} from "@/utils/const";
import SupportProjectStatus from "@/components/enterprise/ProjectDetails/TabItems/SupportProjectStatus.vue";
import SupportActivityTabItem from "@/components/enterprise/ProjectDetails/TabItems/SupportActivityTabItem.vue";
import OutsourceProjectStatus from "@/components/enterprise/ProjectDetails/TabItems/OutsourceProjectStatus.vue";
import PortalIcon from "@/components/module/icon/PortalIcon.vue";
import ProjectCollapse from "@/components/enterprise/project/module/ProjectCollapse.vue";
import BillableTabItem from "@/components/enterprise/ProjectDetails/TabItems/BillableTabItem.vue";

export default {
  name: "SupportProjectDetails",
  components: {
    BillableTabItem,
    ProjectCollapse,
    PortalIcon,
    SupportProjectStatus,
    ProjectEdit,
    BriefData,
    WorkersList,
    JournalTabItem,
    SyncData,
    InvoiceTabItem,
    SupportActivityTabItem,
  },
  data() {
    return {
      loading: true,
      actualStatus: 0,
      bigTab: 0,
      projectIdentifier: null,
      PROJECT_TYPES,
      selectedYear: new Date().getFullYear(),
      includedTypes: ["FIX", "LOCKUP", "BILLABLE"],
      refreshKey: 0,
    };
  },
  computed: {
    ISSUE_TRACKING_SYSTEM() {
      return ISSUE_TRACKING_SYSTEM;
    },
    projectDetails() {
      return this.projectDetailsGetter(this.projectIdentifier) ?? {};
    },
    ...mapGetters({
      projectDetailsGetter: "support_projects/projectDetails",
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
      employees: "employee/workers",
    }),
  },
  methods: {
    moneyify,
    SHORT_MONTH_NAMES() {
      return SHORT_MONTH_NAMES;
    },
    preloadData: async function (force) {
      await this.$store.dispatch("employee/fetchWorkers");
      this.projectIdentifier = this.$route.params.id;
      let params = new URLSearchParams();
      params.append("year", new Date().getFullYear());
      params.append("includeFixes", "true");
      params.append("includeLockups", "true");
      params.append("includeBillables", "true");
      await this.$store
        .dispatch("support_projects/fetchProjectDetails", {
          projectIdentifier: this.projectIdentifier,
          requestParams: { params },
          force,
        })
        .catch((reason) => {
          if (reason.response.status === 404) this.$router.push("../projects");
        });
      await this.$store.dispatch(
        "enterprise_core/fetchIssues",
        this.projectDetails.id
      );
      this.setActualStatus();
      this.loading = false;
    },
    async refreshData(force, year, types) {
      if (year) this.selectedYear = year;
      if (types) this.includedTypes = types;
      let params = new URLSearchParams();
      params.append("year", year ?? new Date().getFullYear());
      params.append("includeFixes", !!this.includedTypes?.includes("FIX"));
      params.append("includeLockups", !!this.includedTypes?.includes("LOCKUP"));
      params.append(
        "includeBillables",
        !!this.includedTypes?.includes("BILLABLE")
      );
      await this.$store.dispatch("support_projects/fetchProjectDetails", {
        projectIdentifier: this.projectIdentifier,
        requestParams: { params },
        force,
      });
      this.refreshKey++;
    },
    setActualStatus() {
      if (this.projectDetails.status === "REQUEST") this.actualStatus = 0;
      else if (this.projectDetails.status === "APPROVED") this.actualStatus = 1;
      else if (this.projectDetails.status === "ONGOING") this.actualStatus = 2;
      else if (this.projectDetails.status === "DONE") this.actualStatus = 3;
    },
  },
  async created() {
    await this.preloadData();
    if (this.projectDetails.archived) {
      this.$buefy.toast.open({
        duration: 5000,
        message: this.$tf(
          "projectPage.archived.notification|Ez a projekt archiválva van!"
        ),
        position: "is-top",
        type: "is-danger",
      });
    }
  },
};
</script>

<style scoped></style>
