import { formatDate, newLogger } from "@/utils/util";
import network from "../../../utils/network";
import StoreCore from "../../StoreCore";
import dayjs from "dayjs";

let logger = newLogger("WorkLogStore");

const convertSeconds = (seconds) => {
  if (!seconds) {
    return 0;
  }
  let inMinutes = Math.floor(seconds / 60);
  let hours = Math.floor(inMinutes / 60);
  let minutes = inMinutes % 60;
  return `${hours ? hours + "h" : ""}${minutes ? minutes + "m" : ""}`;
};

const urls = {
  employeeTime: "/enterprise/user/timesheet",
  myTime: "/enterprise/mine/timesheet",
  employeeTimeExport: (user, year, month) =>
    `/enterprise/user/timesheet/export/${user}?year=${year}&month=${month}`,
  myTimeExport: (year, month) =>
    `/enterprise/mine/timesheet/export?year=${year}&month=${month}`,
  refreshSpecific: (user, year, month) =>
    `/enterprise/user/timesheet/${user}/refresh?year=${year}&month=${month}`,
  expectedHours: "/census/work-schedule/expected-hours",
  worklog: "/enterprise/worklog",
};

/**
 * The Worklog store handles data about employee timesheets / worklogs
 */

const initState = () => {
  return {
    expectedWeek: {},
    myExpectedWeek: {},
    worklogWeek: {},
    myWorklogWeek: {},
    expected: {
      2020: {},
      2021: {},
      2022: {},
      2023: {},
      2024: {},
      2025: {}, // fixme:: God help us in 2026
    },
    myExpected: {
      2020: {},
      2021: {},
      2022: {},
      2023: {},
      2024: {},
      2025: {}, // fixme:: God help us in 2026
    },
    worklogs: {
      2020: {},
      2021: {},
      2022: {},
      2023: {},
      2024: {},
      2025: {}, // fixme:: God help us in 2026
    },
    myWorklogs: {
      2020: {},
      2021: {},
      2022: {},
      2023: {},
      2024: {},
      2025: {}, // fixme:: God help us in 2026
    },
  };
};

let generateRequestId = function () {
  return Math.floor(Math.random() * 100000);
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch }, { params, force }) {
    try {
      let data = await this.remoteRequest(
        "get",
        `${urls.employeeTime}/${params.user}?year=${params.year}&month=${params.month}`,
        null,
        true,
        dispatch,
        "Timesheetek betöltése...",
        null,
        force
      );
      commit("save", {
        user: params.user,
        year: params.year,
        month: params.month,
        data: data,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchMine: async function ({ commit, dispatch }, { params, force }) {
    try {
      let data = await this.remoteRequest(
        "get",
        `${urls.myTime}?year=${params.year}&month=${params.month}`,
        null,
        true,
        dispatch,
        "Timesheejeim betöltése...",
        null,
        force
      );
      commit("saveMine", {
        year: params.year,
        month: params.month,
        data: data,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  exportMine: async function ({ dispatch }, { year, month }) {
    let requestId = generateRequestId();
    dispatch(
      "loading/startLoading",
      {
        id: requestId,
        type: "GET",
        url: urls.myTimeExport(year, month),
        flair: "Timesheet exportálása...",
      },
      { root: true }
    );

    try {
      const axios = await network.excelConnection();
      const { data } = await axios.get(urls.myTimeExport(year, month));

      dispatch(
        "loading/finishLoading",
        { id: requestId, result: true },
        { root: true }
      );

      return data;
    } catch (err) {
      logger.error(err);
      dispatch(
        "loading/finishLoading",
        { id: requestId, result: false },
        { root: true }
      );
    }
  },
  export: async function ({ dispatch }, { user, year, month }) {
    let requestId = generateRequestId();
    dispatch(
      "loading/startLoading",
      {
        id: requestId,
        type: "GET",
        url: urls.employeeTimeExport(user, year, month),
        flair: "Timesheet exportálása...",
      },
      { root: true }
    );

    try {
      const axios = await network.excelConnection();
      const { data } = await axios.get(
        urls.employeeTimeExport(user, year, month)
      );

      dispatch(
        "loading/finishLoading",
        { id: requestId, result: true },
        { root: true }
      );

      return data;
    } catch (err) {
      logger.error(err);
      dispatch(
        "loading/finishLoading",
        { id: requestId, result: false },
        { root: true }
      );
    }
  },
  fetchWeek: async function ({ commit, dispatch }, params) {
    try {
      let data = await this.remoteRequest(
        "get",
        `${urls.employeeTime}/${params.from}/${params.to}/${params.user}`,
        null,
        true,
        dispatch,
        "Timesheetek betöltése..."
      );
      commit("saveWeek", {
        weekStart: params.from,
        data: data,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchMyWeek: async function ({ commit, dispatch }, params) {
    try {
      let data = await this.remoteRequest(
        "get",
        `${urls.myTime}/${params.from}/${params.to}`,
        null,
        true,
        dispatch,
        "Timesheetjeim betöltése..."
      );
      commit("saveMyWeek", {
        weekStart: params.from,
        data: data,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchExpected: async function ({ commit, dispatch }, params) {
    const fromDate = formatDate(new Date(params.year, params.month - 1, 1));
    const toDate = formatDate(new Date(params.year, params.month, 0));
    try {
      let data = await this.remoteRequest(
        "get",
        `${urls.expectedHours}/${params.user}?from=${fromDate}&to=${toDate}`,
        null,
        true,
        dispatch,
        "Elvárt óraszámok betöltése..."
      );
      commit("saveExpected", {
        user: params.user,
        year: params.year,
        month: params.month,
        data: data,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchMyExpected: async function ({ commit, dispatch }, params) {
    const fromDate = formatDate(new Date(params.year, params.month - 1, 1));
    const toDate = formatDate(new Date(params.year, params.month, 0));
    try {
      let data = await this.remoteRequest(
        "get",
        `${urls.expectedHours}/mine?from=${fromDate}&to=${toDate}`,
        null,
        true,
        dispatch,
        "Elvárt óraszámaim betöltése..."
      );
      commit("saveMyExpected", {
        year: params.year,
        month: params.month,
        data: data,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchExpectedWeek: async function ({ commit, dispatch }, params) {
    try {
      let data = await this.remoteRequest(
        "get",
        `${urls.expectedHours}/${params.user}?from=${params.from}&to=${params.to}`,
        null,
        true,
        dispatch,
        "Elvárt óraszámok betöltése..."
      );
      commit("saveExpectedWeek", {
        weekStart: params.from,
        data: data,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchMyExpectedWeek: async function ({ commit, dispatch }, params) {
    try {
      let data = await this.remoteRequest(
        "get",
        `${urls.expectedHours}/mine?from=${params.from}&to=${params.to}`,
        null,
        true,
        dispatch,
        "Elvárt óraszámaim betöltése..."
      );
      commit("saveMyExpectedWeek", {
        weekStart: params.from,
        data: data,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  refreshSpecific: async function ({ dispatch }, params) {
    try {
      await this.remoteRequest(
        "get",
        urls.refreshSpecific(params.user, params.year, params.month),
        null,
        true,
        dispatch,
        "Timesheetek frissítése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  refreshMineSpecific: async function ({ dispatch }, params) {
    try {
      await this.remoteRequest(
        "get",
        `${urls.myTime}/refresh?year=${params.year}&month=${params.month}`,
        null,
        true,
        dispatch,
        "worklog.refreshMineSpecific.flair|Timesheeteim frissítése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "worklog.refreshMineSpecific.progress|Timesheeteim frissítése folyamatban...",
          fail: "worklog.refreshMineSpecific.fail|Timesheeteim frissítése sikertelen!",
          success:
            "worklog.refreshMineSpecific.success|Timesheeteim frissítése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  postWorklog: async function ({ commit, dispatch }, params) {
    try {
      await this.remoteRequest(
        "post",
        urls.worklog,
        params,
        false,
        dispatch,
        "worklog.postWorklog.flair|Logolás",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "worklog.postWorklog.progress|Logolás folyamatban...",
          fail: "worklog.postWorklog.fail|Logolás sikertelen!",
          success: "worklog.postWorklog.success|Logolás sikeres!",
        }
      );

      // commit("saveExpectedWeek", {
      //   weekStart: params.from,
      //   data: expectedResponse.data,
      // });
      return Promise.resolve();
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      return Promise.reject(err);
    }
  }.bind(storeCore),
  deleteWorklog: async function ({ commit, dispatch }, worklogId) {
    try {
      await this.remoteRequest(
        "delete",
        `${urls.worklog}/${worklogId}`,
        null,
        false,
        dispatch,
        "worklog.deleteWorklog.flair|Logolás törlése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "worklog.deleteWorklog.progress|Logolás törlése folyamatban...",
          fail: "worklog.deleteWorklog.fail|Logolás törlése sikertelen!",
          success: "worklog.deleteWorklog.success|Logolás törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  save: (state, { user, year, month, data }) => {
    if (new Date(data.from) <= new Date() && new Date(data.to) >= new Date()) {
      data.to = formatDate(new Date());
    }
    data.totalSeconds = data.totalTimespent + data.totalUntrackedTimespent; // for the progressBar
    for (let d in data.days) {
      let day = data.days[d];
      day.dailyTotalOvertimeSeconds =
        day.dailyCashOvertime + day.dailyShiftOvertime;
      day.dailyTotalSeconds =
        day.dailyTimespent +
        day.dailyConflictedTimespent +
        day.dailyUntrackedTimespent -
        day.dailyTotalOvertimeSeconds;
    }
    data.days = data.days.sort((fe, se) => fe.day.localeCompare(se.day));
    if (state.worklogs[year][user]) {
      delete state.worklogs[year][user][month];
      state.worklogs[year][user][month] = data;
    } else {
      state.worklogs[year][user] = {};
      state.worklogs[year][user][month] = data;
    }
  },
  saveMine: (state, { year, month, data }) => {
    if (new Date(data.from) <= new Date() && new Date(data.to) >= new Date()) {
      data.to = formatDate(new Date());
    }
    data.totalSeconds = data.totalTimespent + data.totalUntrackedTimespent; // for the progressBar
    for (let d in data.days) {
      let day = data.days[d];
      day.dailyTotalOvertimeSeconds =
        day.dailyCashOvertime + day.dailyShiftOvertime;
      day.dailyTotalSeconds =
        day.dailyTimespent +
        day.dailyConflictedTimespent +
        day.dailyUntrackedTimespent -
        day.dailyTotalOvertimeSeconds;
    }
    data.days = data.days.sort((fe, se) => fe.day.localeCompare(se.day));
    if (state.myWorklogs[year]) {
      delete state.myWorklogs[year][month];
      state.myWorklogs[year][month] = data;
    } else {
      state.myWorklogs[year] = {};
      state.myWorklogs[year][month] = data;
    }
  },
  saveWeek: (state, { weekStart, data }) => {
    if (new Date(data.from) <= new Date() && new Date(data.to) >= new Date()) {
      data.to = formatDate(new Date());
    }
    data.totalSeconds = data.totalTimespent + data.totalUntrackedTimespent; // for the progressBar
    for (let d in data.days) {
      let day = data.days[d];
      day.dailyTotalOvertimeSeconds =
        day.dailyCashOvertime + day.dailyShiftOvertime;
      day.dailyTotalSeconds =
        day.dailyTimespent +
        day.dailyConflictedTimespent +
        day.dailyUntrackedTimespent -
        day.dailyTotalOvertimeSeconds;
    }
    data.days = data.days.sort((fe, se) => fe.day.localeCompare(se.day));
    if (state.worklogWeek) {
      delete state.worklogWeek[weekStart];
    }
    state.worklogWeek[weekStart] = data;
  },
  saveMyWeek: (state, { weekStart, data }) => {
    if (new Date(data.from) <= new Date() && new Date(data.to) >= new Date()) {
      data.to = formatDate(new Date());
    }
    data.totalSeconds = data.totalTimespent + data.totalUntrackedTimespent; // for the progressBar
    for (let d in data.days) {
      let day = data.days[d];
      day.dailyTotalOvertimeSeconds =
        day.dailyCashOvertime + day.dailyShiftOvertime;
      day.dailyTotalSeconds =
        day.dailyTimespent +
        day.dailyConflictedTimespent +
        day.dailyUntrackedTimespent -
        day.dailyTotalOvertimeSeconds;
    }
    data.days = data.days.sort((fe, se) => fe.day.localeCompare(se.day));
    if (state.myWorklogWeek) {
      delete state.myWorklogWeek[weekStart];
    }
    state.myWorklogWeek[weekStart] = data;
  },
  saveExpected: (state, { user, year, month, data }) => {
    let today = formatDate(new Date());
    let neededDays = 0;
    let neededHours = 0;

    const dataToSave = [];

    for (let d of Object.keys(data.expectedHours)) {
      if (dayjs(d).isBefore(dayjs(today).add(1, "day"))) {
        dataToSave.push({
          userId: data.userId,
          day: d,
          hours: data.expectedHours[d],
        });
        if (data.expectedHours[d]) {
          neededDays += 1;
          neededHours += data.expectedHours[d];
        }
      }
    }

    dataToSave.days = neededDays;
    dataToSave.total = neededHours;
    dataToSave.userId = data.userId;

    if (state.expected[year][user]) {
      delete state.expected[year][user][month];
      state.expected[year][user][month] = dataToSave;
    } else {
      state.expected[year][user] = {};
      state.expected[year][user][month] = dataToSave;
    }
  },
  saveMyExpected: (state, { year, month, data }) => {
    let today = formatDate(new Date());
    let neededDays = 0;
    let neededHours = 0;

    const dataToSave = [];

    for (let d of Object.keys(data.expectedHours)) {
      if (dayjs(d).isBefore(dayjs(today).add(1, "day"))) {
        dataToSave.push({
          userId: data.userId,
          day: d,
          hours: data.expectedHours[d],
        });
        if (data.expectedHours[d]) {
          neededDays += 1;
          neededHours += data.expectedHours[d];
        }
      }
    }

    dataToSave.days = neededDays;
    dataToSave.total = neededHours;
    dataToSave.userId = data.userId;

    if (state.myExpected[year]) {
      delete state.myExpected[year][month];
      state.myExpected[year][month] = dataToSave;
    } else {
      state.myExpected[year] = {};
      state.myExpected[year][month] = dataToSave;
    }
  },
  saveExpectedWeek: (state, { weekStart, data }) => {
    let today = formatDate(new Date());
    let neededDays = 0;
    let neededHours = 0;

    const dataToSave = [];

    for (let d of Object.keys(data.expectedHours)) {
      if (dayjs(d).isBefore(dayjs(today).add(1, "day"))) {
        dataToSave.push({
          userId: data.userId,
          day: d,
          hours: data.expectedHours[d],
        });
        if (data.expectedHours[d]) {
          neededDays += 1;
          neededHours += data.expectedHours[d];
        }
      }
    }

    dataToSave.days = neededDays;
    dataToSave.total = neededHours;
    dataToSave.userId = data.userId;

    if (state.expectedWeek) {
      delete state.expectedWeek[weekStart];
    }
    state.expectedWeek[weekStart] = dataToSave;
  },
  saveMyExpectedWeek: (state, { weekStart, data }) => {
    let today = formatDate(new Date());
    let neededDays = 0;
    let neededHours = 0;

    const dataToSave = [];

    for (let d of Object.keys(data.expectedHours)) {
      if (dayjs(d).isBefore(dayjs(today).add(1, "day"))) {
        dataToSave.push({
          userId: data.userId,
          day: d,
          hours: data.expectedHours[d],
        });
        if (data.expectedHours[d]) {
          neededDays += 1;
          neededHours += data.expectedHours[d];
        }
      }
    }

    dataToSave.days = neededDays;
    dataToSave.total = neededHours;
    dataToSave.userId = data.userId;

    if (state.myExpectedWeek) {
      delete state.myExpectedWeek[weekStart];
    }
    state.myExpectedWeek[weekStart] = dataToSave;
  },
};

const getters = {
  worklogOf: (state) => (user, year, month) => {
    if (!state.worklogs[year][user]) return undefined;
    return state.worklogs[year][user][month];
  },
  myWorklog: (state) => (year, month) => {
    if (!state.myWorklogs[year]) return undefined;
    return state.myWorklogs[year][month];
  },
  expectedOf: (state) => (user, year, month) => {
    if (!state.expected[year][user]) return undefined;
    return state.expected[year][user][month];
  },
  myExpected: (state) => (year, month) => {
    if (!state.myExpected[year]) return undefined;
    return state.myExpected[year][month];
  },
  expectedOfWeek: (state) => (weekStart) => {
    return state.expectedWeek[weekStart];
  },
  myExpectedOfWeek: (state) => (weekStart) => {
    return state.myExpectedWeek[weekStart];
  },
  worklogOfWeek: (state) => (weekStart) => {
    return state.worklogs[weekStart];
  },
  myWorklogOfWeek: (state) => (weekStart) => {
    return state.myWorklogWeek[weekStart];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
